import {
  COMPLETED,
  IN_PROGRESS,
  NOT_STARTED,
} from 'utils/constants/reportStatus';

import { format, parseJSON } from 'date-fns';

import { Text, Progress, Badge } from '@chakra-ui/react';

const getStatusColor = (completedCount, totalCount) => {
  if (completedCount > totalCount) return 'green';
  if (completedCount === 0) return 'gray';
  if (completedCount !== totalCount) return 'blue';
  return 'green';
};

const getStatusText = (completedCount, totalCount) => {
  if (completedCount > totalCount) return COMPLETED;
  if (completedCount === 0) return NOT_STARTED;
  if (completedCount !== totalCount) return IN_PROGRESS;
  return COMPLETED;
};

export const getReportSummary = (completionReports, type) => {
  const summary = {
    percentage: 0,
    count: 0,
  };

  if (completionReports.length === 0) return summary;

  const filterCondition = (item) => {
    switch (type) {
      case COMPLETED:
        return (
          item.completedActivities === item.totalActivities ||
          item.completedActivities > item.totalActivities
        );
      case IN_PROGRESS:
        return (
          item.completedActivities > 0 &&
          item.completedActivities < item.totalActivities
        );
      case NOT_STARTED:
        return item.completedActivities === 0;
      default:
        return false;
    }
  };

  summary.count = completionReports.filter(filterCondition).length;
  summary.percentage = parseFloat(
    ((summary.count / completionReports.length) * 100).toFixed(2)
  );
  return summary;
};

export const completionReportColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    cell: (row) => (
      <div> {row.name.indexOf('EXT') !== -1 ? `EXT-${row.id}` : row.id}</div>
    ),
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => (
      <Text fontWeight={500} as="i">
        {row.name}
      </Text>
    ),
  },
  {
    name: 'Email',
    selector: (row) => row.email,
    wrap: true,
    width: '300px',
    sortable: true,
  },
  {
    name: 'Department',
    selector: (row) => row.department,
    sortable: true,
    cell: (row) => <Text fontWeight={550}>{row.department}</Text>,
  },
  {
    name: 'Manager',
    selector: (row) => row.managerName,
    sortable: true,
  },
  {
    name: 'Progress',
    selector: (row) => row.completedActivities,
    sortable: true,
    cell: (row) => (
      <div>
        <Progress
          width={100}
          colorScheme={getStatusColor(
            row.completedActivities,
            row.totalActivities
          )}
          size="sm"
          max={row.totalActivities}
          value={
            row.completedActivities > row.totalActivities
              ? 100
              : row.completedActivities
          }
        />
        <Badge
          colorScheme={getStatusColor(
            row.completedActivities,
            row.totalActivities
          )}
        >
          {row.completedActivities > row.totalActivities
            ? 100
            : Math.round((row.completedActivities / row.totalActivities) * 100)}
          %
        </Badge>
      </div>
    ),
  },
  {
    name: 'Status',
    selector: (row) => row.completedActivities,
    cell: (row) => (
      <Badge
        colorScheme={getStatusColor(
          row.completedActivities,
          row.totalActivities
        )}
      >
        {getStatusText(row.completedActivities, row.totalActivities)}
      </Badge>
    ),
  },
  {
    name: 'Last Activity',
    selector: (row) => row.lastActivity,
    cell: (row) => (
      <div>
        {row.lastActivity
          ? format(parseJSON(row.lastActivity), 'MMMM d, yyyy (h:mm a)')
          : 'N/A'}
      </div>
    ),
  },
];
