import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useParams,
  useNavigate,
  Link as ReactRouterLink,
} from 'react-router-dom';

import { DndContext, closestCorners } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  LinkBox,
  Stack,
  Skeleton,
} from '@chakra-ui/react';

import { EditIcon, ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { MdSettings } from 'react-icons/md';

import { ReducerStatus, CompanionMode } from 'utils/constants';

import { fetchCourseByIdAction } from 'store/modules/course/course-actions';
import {
  fetchCourseQuestsAction,
  updateCourseQuestOrderNoAction,
} from 'store/modules/quest/quest-actions';

import { courseActions } from 'store/modules/course/course-slice';
import { questActions } from 'store/modules/quest/quest-slice';
import { companionActions } from 'companion/store/companion-slice';

import CreateQuestMd from 'components/quest/modals/CreateQuestMd';
import Breadcrumbs from 'components/common/Breadcrumbs';
import CourseHeader from 'components/course/CourseHeader';
import UpdateCourseMd from 'components/course/modals/UpdateCourseMd';
import DeleteCourseMd from 'components/course/modals/DeleteCourseMd';
import CourseQuestsDraft from 'components/course/CourseQuestsDraft';
import QuestAdminItem from 'components/quest/QuestAdminItem';
import UpdateQuestMd from 'components/quest/modals/UpdateQuestMd';
import DeleteQuestMd from 'components/quest/modals/DeleteQuestMd';
import RemoveQuestMd from 'components/quest/modals/RemoveQuestMd';
import UpdateInteractiveContentMd from 'components/subQuest/modals/interactiveContents/UpdateInteractiveContentMd';
import UpdateQuizMd from 'components/subQuest/modals/quizzes/UpdateQuizMd';
import DeleteSubQuestMd from 'components/subQuest/modals/DeleteSubQuestMd';
import UpdateVideoMd from 'components/subQuest/modals/videos/UpdateVideoMd';

import CreateQuizMd from 'components/subQuest/modals/quizzes/CreateQuizMd';

function CourseAdminPage() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const course = useSelector((state) => state.course.course);
  const courseQuests = useSelector((state) => state.quest.courseQuests);
  const getCourseStatus = useSelector((state) => state.course.getCourseStatus);
  const getQuestsStatus = useSelector((state) => state.quest.getQuestsStatus);

  // Fetch course
  useEffect(() => {
    dispatch(fetchCourseByIdAction(id));
    dispatch(companionActions.setOutlines([]));
  }, [id, dispatch]);

  // Fetch course's quests
  useEffect(() => {
    if (course && getCourseStatus === ReducerStatus.SUCCEEDED) {
      document.title = `Skill Quest - Admin - ${course.name}`;
      dispatch(fetchCourseQuestsAction(course.id));
    }
  }, [course, getCourseStatus, dispatch]);

  // Setup AI context
  useEffect(() => {
    if (course) {
      let couresContext = `CourseName: ${course.name} | Course Description: ${course.description}`;

      if (courseQuests.length > 0) couresContext += '| Current Topics: ';

      for (let i = 0; i < courseQuests.length; i += 1) {
        const { name, description } = courseQuests[i].quest;
        couresContext += `{Topic: ${name} Description: ${description}}`;
      }

      dispatch(companionActions.setContext(couresContext));
      dispatch(companionActions.setMode(CompanionMode.COURSE_OUTLINE));

      dispatch(
        companionActions.setGreetings(
          "I'm your AI Companion Bot ready to assist you. \n \n" +
            `Currently, you're editing "${course.name}". I'm here to help you with anything you need, whether it's understanding course content, creating engaging materials, or mastering new concepts. \n \n` +
            "Just let me know how I can assist you further, and together we'll make your learning experience productive and enjoyable!📚"
        )
      );
    }
  }, [course, courseQuests, dispatch]);

  const handlerOpenDeleteConfirmation = () => {
    dispatch(courseActions.toggleDeleteModal(true));
  };

  const handleOpenUpdateCourseModal = () => {
    dispatch(courseActions.toggleUpdateModal(true));
  };

  const getCourseQuestById = (selectedId) =>
    courseQuests.findIndex((courseQuest) => courseQuest.id === selectedId);

  const handleChangeOrderNo = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    const originalPos = getCourseQuestById(active.id);
    const newPos = getCourseQuestById(over.id);

    dispatch(
      updateCourseQuestOrderNoAction({
        courseId: id,
        questId: courseQuests.find(
          (courseQuest) => courseQuest.id === active.id
        ).quest.id,
        newOrderNo: newPos + 1,
      })
    );

    dispatch(
      questActions.setCourseQuests(arrayMove(courseQuests, originalPos, newPos))
    );
  };

  if (getCourseStatus === ReducerStatus.FAILED) {
    navigate('/404');
  }

  return (
    <>
      {/* <SideNavigationSm isOpen={isOpen} onClose={onClose} /> */}
      <UpdateCourseMd />
      <UpdateQuestMd />
      <UpdateVideoMd />
      <DeleteCourseMd />
      <DeleteQuestMd />
      <RemoveQuestMd />
      <DeleteSubQuestMd />

      <UpdateInteractiveContentMd />
      <UpdateQuizMd />

      <CreateQuizMd />

      <Flex minH="100vh" ml={{ base: 0, md: 20 }}>
        {/* <SideNavigation /> */}
        <Box flex="1" ml={2} p="5">
          <Flex direction={{ base: 'column', lg: 'row' }}>
            <Box flex="1">
              <Breadcrumbs
                mb={4}
                links={[
                  { name: 'Home', path: '/' },
                  { name: 'Courses', path: '/admin/courses' },
                  {
                    name: course?.name,
                    path: `/admin/courses/${id}`,
                  },
                ]}
              />

              <Flex direction={{ base: 'column', sm: 'row' }}>
                <CourseHeader
                  isLoading={getCourseStatus === ReducerStatus.LOADING}
                  course={course}
                  isAdminMode
                  flex="1"
                  mb={2}
                />
                <Menu>
                  <MenuButton
                    as={IconButton}
                    size={{ base: 'sm', md: 'sm' }}
                    colorScheme="teal"
                    aria-label="Options"
                    icon={<MdSettings />}
                    variant="outline"
                  />
                  <MenuList>
                    <LinkBox cursor="pointer">
                      <MenuItem
                        as={ReactRouterLink}
                        to={`/courses/${id}`}
                        icon={<ViewIcon />}
                      >
                        Student&lsquo;s View
                      </MenuItem>
                    </LinkBox>

                    <MenuItem
                      icon={<EditIcon />}
                      onClick={() => handleOpenUpdateCourseModal()}
                    >
                      Edit Course
                    </MenuItem>
                    <MenuItem
                      icon={<DeleteIcon />}
                      onClick={() => handlerOpenDeleteConfirmation()}
                    >
                      Delete Course
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>

              <Flex
                mt={4}
                direction={{ base: 'column', md: 'row' }}
                justify="end"
                gap={4}
              >
                {course && <CreateQuestMd id={parseInt(id, 10)} />}
              </Flex>

              {course && <CourseQuestsDraft />}

              <Box mt={7}>
                {getQuestsStatus === ReducerStatus.LOADING ? (
                  <Stack>
                    <Skeleton mb={6} height="72px" borderRadius="md" />
                    <Skeleton mb={6} height="72px" borderRadius="md" />
                    <Skeleton mb={6} height="72px" borderRadius="md" />
                    <Skeleton mb={6} height="72px" borderRadius="md" />
                  </Stack>
                ) : (
                  <DndContext
                    onDragEnd={handleChangeOrderNo}
                    collisionDetection={closestCorners}
                  >
                    <SortableContext
                      items={courseQuests}
                      strategy={verticalListSortingStrategy}
                    >
                      {courseQuests.map((courseQuest) => (
                        <QuestAdminItem
                          key={courseQuest.id}
                          courseId={course.id}
                          id={courseQuest.id}
                          courseQuest={courseQuest}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                )}
              </Box>
            </Box>

            {/* <Activities /> */}
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

export default CourseAdminPage;
