import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Checkbox,
  Text,
  Alert,
  AlertIcon,
  useColorModeValue,
} from '@chakra-ui/react';

function QuestionItem({
  position,
  question,
  selectedValue,
  handleSelectAnswer,
}) {
  const {
    questionHtml,
    questionType,
    hasMultipleAnswer,
    multipleChoiceChoicesJson,
  } = question;

  let parsedOptions = '';

  try {
    parsedOptions = JSON.parse(multipleChoiceChoicesJson);
  } catch {
    parsedOptions = null;
  }

  const multipleChoiceType = 1;
  const trueOrFalseType = 2;
  const borderColor = useColorModeValue('gray.200', 'gray.500');
  const activeBorderColor = useColorModeValue('red', 'teal');
  const hoverColor = useColorModeValue('red.100', 'teal');
  const optionsSelectColor = useColorModeValue('red', 'teal');

  const handleCheckboxParentClick = (currentQuestion, option) => {
    if (selectedValue && selectedValue.find((p) => p === option)) {
      handleSelectAnswer(
        currentQuestion,
        selectedValue.filter((v) => v !== option)
      );
    } else {
      handleSelectAnswer(question, [...selectedValue, option]);
    }
  };

  return (
    <Box>
      <Text fontSize="xs" as="samp">
        Question {position}
      </Text>
      <Box
        fontSize="lg"
        fontWeight={500}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(questionHtml),
        }}
      />

      <Box mt={4}>
        {/* Multiple Choice */}
        {questionType.id === multipleChoiceType && hasMultipleAnswer && (
          <CheckboxGroup
            onChange={(values) => {
              handleSelectAnswer(question, values);
            }}
            value={selectedValue}
          >
            {parsedOptions ? (
              parsedOptions.map((choice) => (
                <Box
                  key={uuidv4()}
                  cursor="pointer"
                  onClick={() => {
                    handleCheckboxParentClick(question, choice.option);
                  }}
                  p={4}
                  mb={5}
                  border="1px"
                  borderColor={
                    selectedValue.find((p) => p === choice.option)
                      ? activeBorderColor
                      : borderColor
                  }
                  _hover={{
                    backgroundColor: hoverColor,
                    transition: 'background-color 0.3s ease',
                  }}
                  minH={35}
                >
                  <Checkbox
                    value={choice.option}
                    colorScheme={optionsSelectColor}
                  >
                    {choice.value}
                  </Checkbox>
                </Box>
              ))
            ) : (
              <Alert status="error">
                <AlertIcon />
                An error occurred while displaying the question&apos;s options.
              </Alert>
            )}
          </CheckboxGroup>
        )}

        {/* Multiple Choice (Single Answer) */}
        {questionType.id === multipleChoiceType && !hasMultipleAnswer && (
          <RadioGroup
            onChange={(value) => handleSelectAnswer(question, value)}
            value={selectedValue[0]}
          >
            {parsedOptions ? (
              parsedOptions.map((choice) => (
                <Box
                  key={uuidv4()}
                  cursor="pointer"
                  onClick={() =>
                    selectedValue[0] === choice.option
                      ? handleSelectAnswer(question, [])
                      : handleSelectAnswer(question, [choice.option])
                  }
                  p={4}
                  mb={5}
                  border="1px"
                  borderColor={
                    selectedValue[0] === choice.option
                      ? activeBorderColor
                      : borderColor
                  }
                  _hover={{
                    backgroundColor: hoverColor,
                    transition: 'background-color 0.3s ease',
                  }}
                  minH={35}
                >
                  <Radio
                    size="md"
                    value={choice.option}
                    colorScheme={optionsSelectColor}
                  >
                    {choice.value}
                  </Radio>
                </Box>
              ))
            ) : (
              <Alert status="error">
                <AlertIcon />
                An error occurred while displaying the question&apos;s options.
              </Alert>
            )}
          </RadioGroup>
        )}

        {/* True Or False */}
        {questionType.id === trueOrFalseType && (
          <RadioGroup
            onChange={(value) => handleSelectAnswer(question, value)}
            value={selectedValue[0] ?? ''}
          >
            <Box
              cursor="pointer"
              onClick={() =>
                selectedValue[0] === 'true'
                  ? handleSelectAnswer(question, [])
                  : handleSelectAnswer(question, ['true'])
              }
              p={4}
              border="1px"
              borderColor={
                selectedValue[0] === 'true' ? activeBorderColor : borderColor
              }
              _hover={{
                backgroundColor: hoverColor,
                transition: 'background-color 0.3s ease',
              }}
              minH={35}
            >
              <Radio size="md" value="true" colorScheme={optionsSelectColor}>
                True
              </Radio>
            </Box>
            <Box
              cursor="pointer"
              onClick={() =>
                selectedValue[0] === 'false'
                  ? handleSelectAnswer(question, [])
                  : handleSelectAnswer(question, ['false'])
              }
              p={4}
              mt={4}
              border="1px"
              borderColor={
                selectedValue[0] === 'false' ? activeBorderColor : borderColor
              }
              _hover={{
                backgroundColor: hoverColor,
                transition: 'background-color 0.3s ease',
              }}
              minH={35}
            >
              <Radio size="md" value="false" colorScheme={optionsSelectColor}>
                False
              </Radio>
            </Box>
          </RadioGroup>
        )}
      </Box>
    </Box>
  );
}

QuestionItem.propTypes = {
  position: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  selectedValue: PropTypes.any.isRequired,
  handleSelectAnswer: PropTypes.func.isRequired,
};

export default QuestionItem;
