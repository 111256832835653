import PropTypes from 'prop-types';

import {
  Text,
  Box,
  Textarea,
  Stack,
  Spacer,
  FormLabel,
  RadioGroup,
  Radio,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
  Flex,
} from '@chakra-ui/react';

import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Underline,
} from 'ckeditor5';
import { CKEditor } from '@ckeditor/ckeditor5-react';

/* eslint-disable import/no-unresolved */
import 'ckeditor5/ckeditor5.css';
/* eslint-enable import/no-unresolved */

import { useState } from 'react';

function TrueOrFalse({ question }) {
  const [newQuestion] = useState({
    id: question.id,
    questionHtml: question.questionHtml,
    questionTypeId: 2,
    multipleChoiceChoices: null,
    multipleChoiceAnswers: null,
    trueOrFalseAnswer: question.trueOrFalseAnswer,
    explanation: question.explanation,
    mark: question.mark,
    allowPartialPoints: question.allowPartialPoints,
  });

  return (
    <Box>
      <FormLabel>Question</FormLabel>
      <CKEditor
        editor={ClassicEditor}
        disabled
        config={{
          plugins: [Essentials, Bold, Italic, Paragraph, Underline],
          toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline'],
          isReadOnly: true,
        }}
        data={newQuestion.questionHtml}
      />

      <Flex align="center" mt={4}>
        <Box mr={3}>
          <Text>Mark</Text>
        </Box>
        <Box>
          <NumberInput
            min={0}
            max={100}
            w="100px"
            value={newQuestion.mark}
            isDisabled
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Spacer />
        <Box>
          <Checkbox
            size="lg"
            mr={5}
            isChecked={newQuestion.allowPartialPoints}
            isDisabled
          >
            Allow partial points
          </Checkbox>
        </Box>
      </Flex>
      <Box>
        <RadioGroup
          value={newQuestion.trueOrFalseAnswer ? 'true' : 'false'}
          mt={5}
          mb={5}
          isDisabled
        >
          <Stack>
            <Radio value="true">True</Radio>
            <Radio value="false">False</Radio>
          </Stack>
        </RadioGroup>
      </Box>
      <Box mt={4}>
        <Text fontSize="lg">Explanation</Text>
        <Textarea value={newQuestion.explanation} isDisabled />
      </Box>
    </Box>
  );
}

TrueOrFalse.propTypes = {
  question: PropTypes.object.isRequired,
};

export default TrueOrFalse;
