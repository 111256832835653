import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Text,
  Spacer,
  VStack,
  ScaleFade,
  Grid,
  GridItem,
  Divider,
  Button,
  useBreakpointValue,
  CircularProgress,
  Icon,
} from '@chakra-ui/react';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

import PagingControl from 'components/PagingControl';
import CourseItem from 'components/course/CourseItem';
import CourseItemLoading from 'components/course/CourseItemLoading';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function CustomRight({ onClick }) {
  return (
    <Button
      pos="absolute"
      boxShadow="md"
      colorScheme="blue"
      borderRadius="50%"
      top="50%"
      right={0}
      boxSize={10}
      onClick={onClick}
    >
      <Icon as={FaChevronRight} boxSize={5} />
    </Button>
  );
}

CustomRight.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function CustomLeft({ onClick }) {
  return (
    <Button
      pos="absolute"
      boxShadow="md"
      colorScheme="blue"
      borderRadius="50%"
      top="50%"
      left={0}
      boxSize={10}
      onClick={onClick}
    >
      <Icon as={FaChevronLeft} boxSize={5} />
    </Button>
  );
}

CustomLeft.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function HomePage() {
  document.title = 'Skill Quest - Home';
  const columnLayout = useBreakpointValue({
    base: '1fr',
    sm: '1fr 1fr',
    md: '1fr 1fr',
    lg: '1fr 1fr 1fr',
    xl: '1fr 1fr 1fr 1fr',
  });

  const [accessedCourses, setAccessedCourses] = useState([]);
  const [accessedCourseLoading, setAccessedCourseLoading] = useState(false);
  const [accessedCourseHasNext, setAccessedCourseHasNext] = useState(false);
  const [accessedCourseShowMoreLoading, setAccessedCourseShowMoreLoading] =
    useState(false);
  const [accessedCourseFlag, setAccessedCourseFlag] = useState(true);
  const [accessedCourseCurrentPage, setAccessedCourseCurrentPage] = useState(1);

  const accessedCoursePageSize = 8;

  const [courses, setCourses] = useState([]);
  const [courseLoading, setCourseLoading] = useState(false);
  const [courseCurrentPage, setCourseCurrentPage] = useState(1);
  const [courseMetaData, setCourseMetaData] = useState({
    totalItems: 0,
    hasNext: false,
  });

  const coursePageSize = 12;

  useEffect(() => {
    const fetchCourses = async () => {
      setCourseLoading(true);
      try {
        const response = await axios.get(
          `/courses?currentPage=${courseCurrentPage}&pageSize=${coursePageSize}`
        );
        const { data, metaData } = response.data;
        setCourseMetaData({
          totalItems: metaData.totalItems,
          hasNext: metaData.hasNext,
        });
        setCourses(data);
      } catch {
        setCourses([]);
      }

      setCourseLoading(false);
    };
    fetchCourses();
  }, [courseCurrentPage]);

  useEffect(() => {
    const fetchRecentCourses = async () => {
      setAccessedCourseLoading(true);
      try {
        const response = await axios.get(
          `/courses/recent-accessed?currentPage=1&pageSize=${accessedCoursePageSize}`
        );
        const { data, metaData } = response.data;
        if (data.length === 0) setAccessedCourseFlag(false);
        setAccessedCourses(data);
        setAccessedCourseHasNext(metaData.hasNext);
      } catch {
        setAccessedCourses([]);
        setAccessedCourseFlag(false);
      }

      setAccessedCourseLoading(false);
    };
    fetchRecentCourses();
  }, []);

  const getMoreAccessedCourses = async (
    currentSlide,
    slidesToShow,
    totalItems
  ) => {
    if (currentSlide + slidesToShow === totalItems && accessedCourseHasNext) {
      setAccessedCourseShowMoreLoading(true);
      const response = await axios.get(
        `/courses/recent-accessed?currentPage=${accessedCourseCurrentPage + 1}&pageSize=${accessedCoursePageSize}`
      );
      const { data, metaData } = response.data;

      setAccessedCourseCurrentPage((prevValue) => prevValue + 1);
      setAccessedCourses((prevData) => [...prevData, ...data]);
      setAccessedCourseHasNext(metaData.hasNext);

      setAccessedCourseShowMoreLoading(false);
    }
  };

  return (
    <Box ml={{ base: 0, md: 20 }} p="10">
      {accessedCourseFlag && (
        <>
          <Text
            flex={1}
            fontSize="xl"
            fontWeight="bold"
            mb={2}
            colorScheme="gray"
          >
            Continue where you left off
            {accessedCourseShowMoreLoading && (
              <CircularProgress ml={2} size="20px" isIndeterminate />
            )}
          </Text>

          <VStack align="stretch">
            {accessedCourseLoading ? (
              <Carousel responsive={responsive}>
                {[...Array(4)].map(() => (
                  <Box p={[0, 4]} key={uuidv4()} w="100%">
                    <CourseItemLoading />
                  </Box>
                ))}
              </Carousel>
            ) : (
              <ScaleFade initialScale={0.9} in={!accessedCourseLoading}>
                <Carousel
                  customRightArrow={<CustomRight />}
                  renderButtonGroupOutside={false}
                  customLeftArrow={<CustomLeft />}
                  responsive={responsive}
                  afterChange={(
                    _,
                    { currentSlide, slidesToShow, totalItems }
                  ) => {
                    getMoreAccessedCourses(
                      currentSlide,
                      slidesToShow,
                      totalItems
                    );
                  }}
                >
                  {accessedCourses.map((course) => (
                    <Box p={[0, 4]} key={course.id}>
                      <CourseItem
                        id={course.id}
                        image={course.thumbnail}
                        category={course.category.name}
                        name={course.name}
                        description={course.description}
                      />
                    </Box>
                  ))}
                </Carousel>
              </ScaleFade>
            )}
          </VStack>

          <Divider my={4} />
        </>
      )}

      <Text flex={1} fontSize="xl" fontWeight="bold" mb={5} colorScheme="gray">
        Browse Our Courses
      </Text>
      <VStack minHeight="100vh" align="stretch">
        {courseLoading ? (
          <Grid templateColumns={columnLayout}>
            {[...Array(12)].map(() => (
              <GridItem p={[0, 4]} key={uuidv4()} w="100%">
                <CourseItemLoading />
              </GridItem>
            ))}
          </Grid>
        ) : (
          <ScaleFade initialScale={0.9} in={!courseLoading}>
            <VStack minHeight="100vh" align="stretch">
              <Grid templateColumns={columnLayout}>
                {courses.map((course) => (
                  <GridItem p={[0, 4]} w="100%" key={course.id}>
                    <CourseItem
                      id={course.id}
                      image={course.thumbnail}
                      category={course.category.name}
                      name={course.name}
                      description={course.description}
                    />
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          </ScaleFade>
        )}

        <Spacer mt={12} />

        <PagingControl
          currentPage={courseCurrentPage}
          onGoToPage={(newPageNumber) => setCourseCurrentPage(newPageNumber)}
          pageSize={coursePageSize}
          totalCount={courseMetaData.totalItems}
          hasNext={courseMetaData.hasNext}
          totalCountDescription="courses available"
        />
      </VStack>
    </Box>
  );
}

export default HomePage;
