import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useRef, useState, useEffect } from 'react';
import FormikFormControl from 'components/common/forms/FormikFormControl';
import useEmailsInput from 'hooks/useEmailsInput';
import useTagsInput from 'hooks/useTagsInput';
import MultiWordInput from 'components/common/forms/MultiWordInput';

function SubQuestForm({
  questName,
  subQuestFormInputs,
  triggerSubQuestForm,
  setSubQuestFormInputs,
  setIsSubQuestFormValid,
}) {
  const [isPublished, setIsPublished] = useState('false');
  const [formInputs, setIsFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
  });

  const formikForm = useRef();

  const {
    tags,
    inputTags,
    setTags,
    handleTagsChange,
    handleTagRemove,
    handleTagAdd,
    handleTagKeyDown,
  } = useTagsInput();

  const {
    emails,
    inputEmails,
    setEmails,
    handleEmailsChange,
    handleEmailRemove,
    handleEmailAdd,
    handleEmailKeyDown,
  } = useEmailsInput();

  const formValidation = Yup.object({
    name: Yup.string().max(155).required('Required'),
    objective: Yup.string().max(1000).required('Required'),
    code: Yup.string().min(5).max(20).required('Required'),
  });

  useEffect(() => {
    if (triggerSubQuestForm) {
      if (triggerSubQuestForm === -1) {
        setTags([]);
        setEmails([]);
        setIsPublished('false');
        formikForm?.current?.resetForm();
      } else {
        formikForm?.current?.submitForm();
        if (Object.keys(formikForm?.current.touched).length > 0) {
          setIsSubQuestFormValid(formikForm?.current.isValid);
        }
      }
    }
  }, [
    triggerSubQuestForm,
    setIsSubQuestFormValid,
    setTags,
    setEmails,
    setIsPublished,
  ]);

  useEffect(() => {
    const { name, objective, code } = subQuestFormInputs;

    setIsFormInputs({ name, objective, code });
  }, [subQuestFormInputs]);

  useEffect(() => {
    if (subQuestFormInputs.tags && subQuestFormInputs.tags.length > 0)
      setTags(subQuestFormInputs.tags);
    if (subQuestFormInputs.groups && subQuestFormInputs.groups.length > 0)
      setEmails(subQuestFormInputs.groups);
    if (subQuestFormInputs.isPublished)
      setIsPublished(subQuestFormInputs.isPublished);
  }, [
    subQuestFormInputs.tags,
    subQuestFormInputs.groups,
    subQuestFormInputs.isPublished,
    setTags,
    setEmails,
    setIsPublished,
  ]);

  const handlePublishStatusChange = (e) => {
    setIsPublished(e);
  };

  const handleSubmitForms = (values) => {
    setSubQuestFormInputs({ ...values, isPublished, tags, groups: emails });
  };

  return (
    <>
      <Text fontSize="md" mb={5}>
        You are currently adding a sub-quest to this quest:&nbsp;
        <Text as="span" fontWeight={550}>
          {questName}
        </Text>
      </Text>

      <VStack spacing={6} align="stretch">
        <Formik
          enableReinitialize
          innerRef={formikForm}
          initialValues={formInputs}
          onSubmit={(values) => {
            handleSubmitForms(values);
          }}
          validationSchema={formValidation}
        >
          {() => (
            <Form>
              <VStack spacing={6} align="stretch">
                <Flex
                  gap={5}
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                >
                  <Field flex={1} name="name">
                    {({ field, form }) => (
                      <FormikFormControl
                        label="Name"
                        helperText="Enter the name of the sub-quest. Make it concise and descriptive."
                        errors={form.errors.name}
                        touched={form.touched.name}
                      >
                        <Input {...field} />
                      </FormikFormControl>
                    )}
                  </Field>

                  <Box
                    w={{
                      base: '100%',
                      md: '380px',
                    }}
                  >
                    <Field name="code">
                      {({ field, form }) => (
                        <FormikFormControl
                          label="Sub-Quest Code"
                          helperText=" This code will be used to distinguish the sub-quest from others."
                          errors={form.errors.code}
                          touched={form.touched.code}
                        >
                          <Input {...field} />
                        </FormikFormControl>
                      )}
                    </Field>
                  </Box>
                </Flex>

                <Field name="objective">
                  {({ field, form }) => (
                    <FormikFormControl
                      label="Objective"
                      helperText="Provide a detailed objective of the sub-quest. Mention the key objectives and what learners can expect to gain."
                      errors={form.errors.objective}
                      touched={form.touched.objective}
                    >
                      <Textarea {...field} />
                    </FormikFormControl>
                  )}
                </Field>

                <MultiWordInput
                  label="Tags"
                  helperText="Enter relevant tags and press Enter after each one to add to the list. Tags help in categorizing and searching for sub-quests. Example: 'programming, web development, beginner.'"
                  newWordKeyName="newTag"
                  newWord={inputTags.newTag}
                  words={tags}
                  isWordError={inputTags.isTagError}
                  errorMessage={inputTags.errorMessage}
                  onWordChange={handleTagsChange}
                  onWordAdd={handleTagAdd}
                  onWordKeyDown={handleTagKeyDown}
                  onWordRemove={handleTagRemove}
                />

                <MultiWordInput
                  label="Owner Groups"
                  helperText="Specify the distribution lists, groups, or emails that have the authority to modify this sub-quest. Press Enter after each entry to add it to the list. This ensures proper access control."
                  newWordKeyName="newEmail"
                  newWord={inputEmails.newEmail}
                  words={emails}
                  isWordError={inputEmails.isEmailError}
                  errorMessage={inputEmails.errorMessage}
                  onWordChange={handleEmailsChange}
                  onWordAdd={handleEmailAdd}
                  onWordKeyDown={handleEmailKeyDown}
                  onWordRemove={handleEmailRemove}
                />

                <FormikFormControl
                  label="Published Status"
                  helperText="Choose between 'Draft' if the course is still in progress and not readyfor public access, or 'Published' if the sub-quest is ready for learners."
                >
                  <RadioGroup
                    value={isPublished}
                    onChange={handlePublishStatusChange}
                  >
                    <HStack spacing={5}>
                      <Radio colorScheme="purple" value="false">
                        Draft
                      </Radio>
                      <Radio colorScheme="green" value="true">
                        Published
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormikFormControl>
              </VStack>
            </Form>
          )}
        </Formik>
      </VStack>
    </>
  );
}

SubQuestForm.defaultProps = {
  questName: null,
};

SubQuestForm.propTypes = {
  questName: PropTypes.string,
  subQuestFormInputs: PropTypes.object.isRequired,
  triggerSubQuestForm: PropTypes.number.isRequired,
  setSubQuestFormInputs: PropTypes.func.isRequired,
  setIsSubQuestFormValid: PropTypes.func.isRequired,
};

export default SubQuestForm;
