import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';

const useToastNotif = () => {
  const toast = useToast();
  const toastIdRef = useRef();

  const updateQuestOrderNo = useSelector(
    (state) => state.quest.updateQuestOrderNo
  );

  const updateSubQuestOrderNo = useSelector(
    (state) => state.subquest.updateSubQuestOrderNo
  );

  const postProgressionStatus = useSelector(
    (state) => state.progression.postProgressionStatus
  );

  const generateToastNotif = (
    statusType,
    title,
    description,
    isClosable,
    isUpdate
  ) => {
    if (isUpdate) {
      toast.update(toastIdRef.current, {
        title: 'Saved',
        description: 'Order number has been updated.',
        status: 'success',
        isClosable: true,
      });
    } else {
      toastIdRef.current = toast({
        title,
        description,
        status: statusType,
        isClosable,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    if (
      updateSubQuestOrderNo.status === ReducerStatus.LOADING ||
      updateQuestOrderNo.status === ReducerStatus.LOADING
    ) {
      toastIdRef.current = toast({
        title: 'Saving',
        description: 'Please wait',
        status: 'loading',
        duration: 9000,
        isClosable: false,
        position: 'top-right',
      });
    } else if (
      updateSubQuestOrderNo.status === ReducerStatus.SUCCEEDED ||
      updateQuestOrderNo.status === ReducerStatus.SUCCEEDED
    ) {
      toast.update(toastIdRef.current, {
        title: 'Saved',
        description: 'Order number has been updated.',
        status: 'success',
        isClosable: true,
      });
    } else if (
      updateSubQuestOrderNo.status === ReducerStatus.FAILED ||
      updateQuestOrderNo.status === ReducerStatus.FAILED
    ) {
      toast.update(toastIdRef.current, {
        title: 'Failed',
        description: 'Something went wrong with the request.',
        status: 'error',
        isClosable: true,
      });
    }
  }, [updateQuestOrderNo, updateSubQuestOrderNo, toast]);

  useEffect(() => {
    if (postProgressionStatus === ReducerStatus.LOADING) {
      toastIdRef.current = toast({
        title: 'Progress Saving',
        description: 'Your progress is saving, please wait',
        status: 'loading',
        position: 'top-right',
      });
    } else if (postProgressionStatus === ReducerStatus.SUCCEEDED) {
      toast.update(toastIdRef.current, {
        title: 'Sub-Quest Completed.',
        description:
          'You have completed this sub-quest activity. You may now proceed to take the next sub-quest, if applicable.',
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } else if (postProgressionStatus === ReducerStatus.FAILED) {
      toast.update(toastIdRef.current, {
        title: 'Error.',
        description: 'Something went wrong with the request.',
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [postProgressionStatus, toast]);

  return {
    generateToastNotif,
  };
};

export default useToastNotif;
