import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ArrowBackIcon, CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import QuestionItemReadOnly from 'components/question/QuestionItemReadOnly';

function QuizResult({
  isShowResult,
  isShowAnswer,
  score,
  totalMark,
  passingScore,
  scoreType,
  questionnaires,
  handleRestart,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const buttonColor = useColorModeValue('red', 'teal');
  const borderColor = useColorModeValue('gray.200', 'teal');

  const getResultStatus = () => {
    let status = '';

    if (isShowResult) {
      if (score >= passingScore) status = 'Passed';
      else status = 'Failed';
    } else status = 'Submitted';

    return status;
  };

  return (
    <Flex h="100%">
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Answers</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start" gap={3}>
              {questionnaires.map((questionnaire, index) => (
                <QuestionItemReadOnly
                  key={questionnaire.id}
                  index={index}
                  answersJson={questionnaire.answersJson}
                  question={questionnaire.question}
                  mark={questionnaire.mark}
                />
              ))}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Center w="100%">
        <Box w={250} borderRadius="md" border="1px" borderColor={borderColor}>
          <Center>
            <Text px={4} pt={4} fontSize="md" fontWeight={500}>
              Quiz Result
            </Text>
          </Center>

          <Divider my={3} />

          <Box px={4} pb={4} fontSize="sm">
            <Center>
              {!isShowResult || score >= passingScore ? (
                <CheckCircleIcon boxSize="50px" color="green.500" />
              ) : (
                <Flex
                  flexDirection="column"
                  justify="center"
                  alignItems="center"
                  bg="red.500"
                  rounded="50px"
                  w="55px"
                  h="55px"
                  textAlign="center"
                >
                  <CloseIcon boxSize="20px" color="white" />
                </Flex>
              )}
            </Center>

            <Center my={4}>
              <Text fontSize="2xl">{getResultStatus()}</Text>
            </Center>

            {isShowResult && (
              <Box>
                <Text as="em">
                  Your Score:&nbsp;
                  <Text as="span" fontWeight={500}>
                    {parseFloat(score.toFixed(2))}
                    {scoreType !== 'Actual Score' ? '%' : ''}
                  </Text>
                </Text>
                <Text>
                  Passing Score:&nbsp;
                  <Text as="span" fontWeight={500}>
                    {passingScore}
                    {scoreType !== 'Actual Score' ? '%' : ''}
                  </Text>
                </Text>
                <Text>
                  Total Mark:&nbsp;
                  <Text as="span" fontWeight={500}>
                    {totalMark}
                    {scoreType !== 'Actual Score' ? '%' : ''}
                  </Text>
                </Text>
              </Box>
            )}

            {isShowAnswer && questionnaires && (
              <Button
                mt={4}
                w="100%"
                colorScheme={buttonColor}
                onClick={onOpen}
              >
                Display Answers
              </Button>
            )}

            <Button
              mt={2}
              w="100%"
              variant="outline"
              colorScheme={buttonColor}
              onClick={handleRestart}
              leftIcon={<ArrowBackIcon />}
            >
              Menu
            </Button>
          </Box>
        </Box>
      </Center>
    </Flex>
  );
}

QuizResult.propTypes = {
  isShowResult: PropTypes.bool.isRequired,
  isShowAnswer: PropTypes.bool.isRequired,
  score: PropTypes.number.isRequired,
  totalMark: PropTypes.number.isRequired,
  passingScore: PropTypes.number.isRequired,
  scoreType: PropTypes.string.isRequired,
  questionnaires: PropTypes.array.isRequired,
  handleRestart: PropTypes.func.isRequired,
};

export default QuizResult;
