import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from 'axios';
import { format, parseJSON } from 'date-fns';
import useToastNotif from 'hooks/useToastNotif';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TOAST_ERROR, TOAST_INFO } from 'utils/constants/toast';

function QuizStart({
  courseId,
  questId,
  subQuestId,
  hasTimeLimit,
  unlimitedAttempts,
  timeLimit,
  totalMark,
  passingScore,
  scoreType,
  handleStartQuiz,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [prevSession, setPrevSession] = useState(null);

  const borderColor = useColorModeValue('gray.200', 'teal');
  const buttonColor = useColorModeValue('red', 'teal');

  const { generateToastNotif } = useToastNotif();

  useEffect(() => {
    const fetchLastSession = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `/quizzes/${courseId}/${questId}/${subQuestId}`
        );

        const { data } = response;
        const {
          dateFinished,
          dateStarted,
          result,
          score,
          showResult,
          attemptsLeft,
          canStart,
          timeSecondsLeft,
          dateBeforeNextAttempt,
        } = data;

        setPrevSession({
          dateFinished,
          dateStarted,
          result,
          score,
          showResult,
          canStart,
          attemptsLeft,
          timeSecondsLeft,
          dateBeforeNextAttempt,
        });
      } catch {
        setPrevSession(null);
      }
      setIsLoading(false);
    };

    fetchLastSession();
  }, [courseId, questId, subQuestId]);

  if (
    prevSession?.canStart &&
    prevSession?.dateFinished == null &&
    prevSession?.dateStarted
  )
    handleStartQuiz(prevSession.timeSecondsLeft);

  const handleInitializeQuiz = async () => {
    if (prevSession.canStart) {
      if (prevSession.dateStarted && !prevSession.dateFinished) {
        handleStartQuiz(prevSession.timeSecondsLeft);
        return;
      }

      setIsLoading(true);

      try {
        await axios.post('/quizzes/start', {
          courseId,
          questId,
          subQuestId,
        });

        generateToastNotif(
          TOAST_INFO,
          'Quiz session started',
          'Good luck and enjoy the learning experience!',
          true,
          false
        );

        handleStartQuiz(prevSession.timeSecondsLeft);
      } catch (error) {
        const errorMessage = 'There something wrong with the request.';
        const errorObject = error.response.data.errors;

        if (errorObject) {
          Object.keys(errorObject).forEach((key) => {
            if (Array.isArray(errorObject[key])) {
              generateToastNotif(
                TOAST_ERROR,
                'Error',
                errorObject[key][0],
                true,
                false
              );
            }
          });
        } else {
          generateToastNotif(TOAST_ERROR, 'Error', errorMessage, true, false);
        }
      }

      setIsLoading(false);
    }
  };

  return (
    <Flex h="100%">
      <Center w="100%">
        <Box w={250} borderRadius="md" border="1px" borderColor={borderColor}>
          <Center>
            <Text px={4} pt={4} fontSize="md" fontWeight={500}>
              Quiz Information
            </Text>
          </Center>

          <Divider my={3} />

          <Box px={4} pb={4} fontSize="sm">
            <Text>
              Time Limit:&nbsp;
              <Text as="span" fontWeight={500}>
                {hasTimeLimit ? timeLimit : 'No time limit'}&nbsp;
                {hasTimeLimit && timeLimit > 0 && 'minute(s)'}
              </Text>
            </Text>
            <Text>
              Total Score:&nbsp;
              <Text as="span" fontWeight={500}>
                {totalMark}
                {scoreType !== 'Actual Score' ? '%' : ''}
              </Text>
            </Text>
            <Text>
              Passing Score:&nbsp;
              <Text as="span" fontWeight={500}>
                {passingScore}
                {scoreType !== 'Actual Score' ? '%' : ''}
              </Text>
            </Text>
            {prevSession && (
              <Text>
                Available Attempts:&nbsp;
                <Text as="span" fontWeight={500}>
                  {!unlimitedAttempts ? prevSession.attemptsLeft : 'Unlimited'}
                  &nbsp;
                  {!unlimitedAttempts &&
                    prevSession.attemptsLeft > 0 &&
                    'attempt(s)'}
                </Text>
              </Text>
            )}

            <Button
              mt={4}
              w="100%"
              colorScheme={buttonColor}
              onClick={handleInitializeQuiz}
              isDisabled={!prevSession?.canStart}
              isLoading={isLoading}
            >
              Start
            </Button>

            {!isLoading &&
              !prevSession?.canStart &&
              !unlimitedAttempts &&
              prevSession?.attemptsLeft === 0 && (
                <Flex justify="center" my={2}>
                  <Text color="red" fontSize="xs" fontWeight={500}>
                    ● Maximum quiz attempts reached.
                  </Text>
                </Flex>
              )}

            {!isLoading &&
              !prevSession?.canStart &&
              prevSession?.dateBeforeNextAttempt && (
                <Flex justify="center" my={2}>
                  <Text color="red" fontSize="xs" fontWeight={500}>
                    ● You need to wait until&nbsp;
                    {format(
                      parseJSON(prevSession.dateBeforeNextAttempt),
                      'MMMM d, yyyy (h:mm a)'
                    )}{' '}
                    &nbsp;before you can retake the quiz, provided you have
                    available attempts
                  </Text>
                </Flex>
              )}

            {prevSession?.showResult && prevSession?.dateFinished && (
              <Box my={2}>
                <Divider mb={2} />

                <Text fontSize="xs" fontWeight={500} mb={2}>
                  Previous Attempt
                </Text>

                <Text fontSize="xs">
                  {format(
                    parseJSON(prevSession.dateFinished),
                    'MMMM d, yyyy (h:mm a)'
                  )}
                </Text>
                <Text fontSize="xs" mb={1}>
                  Score:&nbsp;
                  <Text as="span" fontWeight={500}>
                    {parseFloat(prevSession.score.toFixed(2))}/{totalMark}&nbsp;
                    <Avatar
                      size="2xs"
                      bg={prevSession.result === 'Pass' ? 'green' : 'red'}
                      icon={
                        prevSession.result === 'Pass' ? (
                          <CheckIcon boxSize="2" />
                        ) : (
                          <CloseIcon boxSize="2" />
                        )
                      }
                      mr={2}
                    />
                  </Text>
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </Center>
    </Flex>
  );
}

QuizStart.propTypes = {
  courseId: PropTypes.number.isRequired,
  questId: PropTypes.number.isRequired,
  subQuestId: PropTypes.number.isRequired,
  hasTimeLimit: PropTypes.bool.isRequired,
  unlimitedAttempts: PropTypes.bool.isRequired,
  timeLimit: PropTypes.number.isRequired,
  totalMark: PropTypes.number.isRequired,
  passingScore: PropTypes.number.isRequired,
  scoreType: PropTypes.string.isRequired,
  handleStartQuiz: PropTypes.func.isRequired,
};

export default QuizStart;
