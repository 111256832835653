import {
  Box,
  Text,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Checkbox,
  Alert,
  AlertIcon,
  useColorModeValue,
  Divider,
  Flex,
  Spacer,
  Avatar,
  Badge,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { arrayOfObjects, arrayOfString } from 'utils/jsonParser';

function QuestionItemReadOnly({ index, answersJson, question, mark }) {
  const {
    explanation,
    questionHtml,
    questionType,
    multipleChoiceChoicesJson,
    multipleChoiceAnswersJson,
  } = question;

  const [hasMultipleAnswer, setHasMultipleAnswer] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [parsedOptions, setParsedOptions] = useState([]);
  const [parsedCorrectOptions, setParsedCorrectOptions] = useState([]);
  const [parsedAnswers, setParsedAnswers] = useState([]);

  const multipleChoiceType = 1;
  const trueOrFalseType = 2;

  useEffect(() => {
    setParsedOptions(arrayOfObjects(multipleChoiceChoicesJson));
    setParsedCorrectOptions(arrayOfObjects(multipleChoiceAnswersJson));
    setParsedAnswers(arrayOfString(answersJson));
  }, [multipleChoiceChoicesJson, multipleChoiceAnswersJson, answersJson]);

  useEffect(() => {
    if (parsedCorrectOptions.length > 1) {
      setHasMultipleAnswer(true);
    }
  }, [parsedCorrectOptions]);

  useEffect(() => {
    if (parsedAnswers.length > 0) {
      if (question.questionType.id === trueOrFalseType) {
        if (
          (parsedAnswers[0].toLowerCase() === 'true') ===
          question.trueOrFalseAnswer
        )
          setIsCorrect(true);
      } else if (question.questionType.id === multipleChoiceType) {
        const correctOptions = parsedCorrectOptions.map((o) => o.option).flat();
        if (
          parsedAnswers.length === correctOptions.length &&
          parsedAnswers.slice().sort().join() ===
            correctOptions.slice().sort().join()
        )
          setIsCorrect(true);
      }
    }
  }, [
    parsedAnswers,
    parsedCorrectOptions,
    question.questionType.id,
    question.trueOrFalseAnswer,
  ]);

  const borderColor = useColorModeValue('gray.200', 'gray.500');

  const getBorderOptionColor = (optionValue) => {
    if (!hasMultipleAnswer) {
      let correctValue = '';

      if (question.questionType.id === trueOrFalseType)
        correctValue = String(question.trueOrFalseAnswer);
      else if (question.questionType.id === multipleChoiceType)
        correctValue = parsedCorrectOptions[0].option;

      if (isCorrect && correctValue === optionValue) return 'green';
      if (!isCorrect) {
        if (correctValue === optionValue) return 'green';
        if (correctValue !== optionValue && parsedAnswers[0] === optionValue)
          return 'red';
      }
    } else {
      if (parsedCorrectOptions.find((o) => o.option === optionValue))
        return 'green';
      if (
        parsedCorrectOptions.find((o) => o.option) !== optionValue &&
        parsedAnswers.find((option) => option === optionValue)
      )
        return 'red';
    }

    return borderColor;
  };

  return (
    <Box w="100%">
      <Flex>
        <Text fontSize="xs" as="samp">
          Question {index + 1}
        </Text>

        <Spacer />

        {isCorrect ? (
          <Avatar
            size="2xs"
            bg="green"
            icon={<CheckIcon boxSize="2" />}
            mr={2}
          />
        ) : (
          <Avatar size="2xs" bg="red" icon={<CloseIcon boxSize="2" />} mr={2} />
        )}

        {mark > 0 && (
          <Badge colorScheme="green">+{parseFloat(mark.toFixed(2))}</Badge>
        )}
      </Flex>

      <Box
        fontSize="md"
        fontWeight={500}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(questionHtml),
        }}
      />

      <Box mt={4}>
        {/* Multiple Choice (Multiple Answers) */}
        {questionType.id === multipleChoiceType && hasMultipleAnswer && (
          <CheckboxGroup value={parsedAnswers}>
            {parsedOptions ? (
              parsedOptions.map((choice) => (
                <Box
                  key={uuidv4()}
                  p={4}
                  mb={5}
                  border="1px"
                  borderColor={getBorderOptionColor(choice.option)}
                  minH={35}
                >
                  <Checkbox value={choice.option} colorScheme="gray">
                    {choice.value}
                  </Checkbox>
                </Box>
              ))
            ) : (
              <Alert status="error">
                <AlertIcon />
                An error occurred while displaying the question&apos;s options.
              </Alert>
            )}
          </CheckboxGroup>
        )}

        {/* Multiple Choice (Single Answer) */}
        {questionType.id === multipleChoiceType && !hasMultipleAnswer && (
          <RadioGroup value={parsedAnswers[0]}>
            {parsedOptions ? (
              parsedOptions.map((choice) => (
                <Box
                  key={uuidv4()}
                  p={4}
                  mb={5}
                  border="1px"
                  borderColor={getBorderOptionColor(choice.option)}
                  minH={35}
                >
                  <Radio size="md" value={choice.option} colorScheme="gray">
                    {choice.value}
                  </Radio>
                </Box>
              ))
            ) : (
              <Alert status="error">
                <AlertIcon />
                An error occurred while displaying the question&apos;s options.
              </Alert>
            )}
          </RadioGroup>
        )}

        {/* True Or False */}
        {questionType.id === trueOrFalseType && (
          <RadioGroup value={parsedAnswers[0] ?? ''}>
            <Box
              p={4}
              border="1px"
              borderColor={getBorderOptionColor('true')}
              minH={35}
            >
              <Radio size="md" value="true" colorScheme="gray">
                True
              </Radio>
            </Box>
            <Box
              p={4}
              mt={4}
              border="1px"
              borderColor={getBorderOptionColor('false')}
              minH={35}
            >
              <Radio size="md" value="false" colorScheme="gray">
                False
              </Radio>
            </Box>
          </RadioGroup>
        )}
      </Box>

      {explanation && (
        <Box>
          <Text as="sub" fontWeight={500}>
            Explanation:
          </Text>
          <Text> {explanation}</Text>
        </Box>
      )}

      <Divider mt={4} />
    </Box>
  );
}

QuestionItemReadOnly.defaultProps = {
  question: null,
  answersJson: '',
};

QuestionItemReadOnly.propTypes = {
  index: PropTypes.number.isRequired,
  mark: PropTypes.number.isRequired,
  answersJson: PropTypes.string,
  question: PropTypes.object,
};

export default QuestionItemReadOnly;
