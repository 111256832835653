export const arrayOfString = (jsonString) => {
  try {
    const parsedArrayOfString = JSON.parse(jsonString);
    if (
      Array.isArray(parsedArrayOfString) &&
      parsedArrayOfString.every((string) => typeof string === 'string')
    ) {
      return parsedArrayOfString;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const arrayOfObjects = (jsonString) => {
  try {
    const parsedArrayOfObjects = JSON.parse(jsonString);
    if (
      Array.isArray(parsedArrayOfObjects) &&
      parsedArrayOfObjects.every(
        (item) =>
          typeof item === 'object' && item !== null && !Array.isArray(item)
      )
    ) {
      return parsedArrayOfObjects;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export default arrayOfString;
