import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Flex,
  Icon,
  ScaleFade,
  Text,
  useColorModeValue,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import { MdQuiz, MdVideoLibrary, MdVideoSettings } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { removeHtmlTags } from 'utils/textParser';

function SubQuestItem({
  courseId,
  questId,
  subQuest,
  isMinimalMode = false,
  activeSubQuestId = null,
}) {
  const completedProgression = useSelector((state) =>
    state.progression.progressions.find(
      (p) =>
        p.questId === questId &&
        p.courseId === courseId &&
        p.subQuestId === subQuest.id &&
        p.dateFinished
    )
  );

  const ref = useRef(null);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: '200px' },
    { disconnectOnLeave: false },
    {}
  );

  useEffect(() => {
    if (activeSubQuestId) {
      const container = document.getElementById('subquest-directory-container');
      const element = document.getElementById('active-subquest');

      if (container && element)
        container.scrollTop = element.offsetTop - container.offsetTop - 90;
    }
  }, [activeSubQuestId]);

  const checkIconColor = useColorModeValue('green', 'white');
  const typeColor = useColorModeValue('teal', 'white');

  return (
    <Box
      id={activeSubQuestId === subQuest.id ? 'active-subquest' : uuidv4()}
      w="100%"
    >
      <ScaleFade
        initialScale={0.9}
        in={inViewport}
        whileHover={{ scale: 1.01 }}
      >
        <LinkBox
          cursor="pointer"
          ref={ref}
          w="100%"
          mb={isMinimalMode ? 4 : 6}
          borderWidth="1px"
        >
          <Box
            w="100%"
            p={4}
            bg={useColorModeValue(
              activeSubQuestId === subQuest.id ? '#FFDFDF' : '#f7f7f7',
              activeSubQuestId === subQuest.id ? 'teal' : 'gray.600'
            )}
          >
            <Flex align="center">
              {subQuest.type.id === 1 && (
                <Icon
                  as={MdVideoLibrary}
                  boxSize={isMinimalMode ? 5 : 10}
                  mx={isMinimalMode ? 2 : 4}
                  color={typeColor}
                />
              )}

              {subQuest.type.id === 4 && (
                <Icon
                  as={MdQuiz}
                  boxSize={isMinimalMode ? 5 : 10}
                  mx={isMinimalMode ? 2 : 4}
                  color={typeColor}
                />
              )}

              {subQuest.type.id === 10 && (
                <Icon
                  as={MdVideoSettings}
                  boxSize={isMinimalMode ? 5 : 10}
                  mx={isMinimalMode ? 2 : 4}
                  color={typeColor}
                />
              )}

              <Box flex={1}>
                <Text
                  fontWeight={500}
                  fontSize={isMinimalMode ? 'xs' : 'md'}
                  mb={1}
                >
                  <LinkOverlay
                    as={ReactRouterLink}
                    to={`/courses/${courseId}/quests/${questId}/sub-quests/${subQuest.id}`}
                  >
                    {subQuest.name}
                  </LinkOverlay>
                </Text>
                {!isMinimalMode && (
                  <Text fontSize={isMinimalMode ? 'xs' : 'sm'}>
                    {removeHtmlTags(`${subQuest.objective}`)}
                  </Text>
                )}
              </Box>
              {completedProgression && (
                <CheckCircleIcon boxSize={4} color={checkIconColor} />
              )}
            </Flex>
          </Box>
        </LinkBox>
      </ScaleFade>
    </Box>
  );
}

SubQuestItem.defaultProps = {
  isMinimalMode: false,
  activeSubQuestId: 0,
};

SubQuestItem.propTypes = {
  courseId: PropTypes.number.isRequired,
  questId: PropTypes.number.isRequired,
  subQuest: PropTypes.object.isRequired,
  isMinimalMode: PropTypes.bool,
  activeSubQuestId: PropTypes.number,
};

export default SubQuestItem;
