import PropTypes from 'prop-types';
import {
  Text,
  Box,
  Textarea,
  Button,
  Input,
  Stack,
  Checkbox,
  FormLabel,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Spacer,
} from '@chakra-ui/react';

import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Underline,
} from 'ckeditor5';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import { IoMdClose } from 'react-icons/io';

/* eslint-disable import/no-unresolved */
import 'ckeditor5/ckeditor5.css';

import { useEffect, useState } from 'react';
/* eslint-enable import/no-unresolved */
function MultipleChoice({ question, onUpdate }) {
  const [newQuestion, setNewQuestion] = useState({
    id: question.id,
    questionHtml: question.questionHtml,
    questionTypeId: question.questionTypeId,
    multipleChoiceChoices: question.multipleChoiceChoices,
    multipleChoiceAnswers: question.multipleChoiceAnswers,
    trueOrFalseAnswer: null,
    explanation: question.explanation,
    mark: question.mark,
    allowPartialPoints: question.allowPartialPoints,
    errors: question.errors,
  });

  useEffect(() => {
    if (onUpdate) {
      onUpdate(newQuestion);
    }
  }, [newQuestion, onUpdate]);

  const setQuestionAnswers = (ans) => {
    if (ans != null) {
      const newMultipleChoiceAnswers = question.multipleChoiceChoices.filter(
        (item) => ans.includes(item.option)
      );

      let newErrors = [...newQuestion.errors];
      const errorMultipleChoiceChoices = 'Answer/s are required. ';
      if (newMultipleChoiceAnswers.length === 0) {
        if (!newErrors.includes(errorMultipleChoiceChoices)) {
          newErrors.push(errorMultipleChoiceChoices);
        }
      } else {
        newErrors = newErrors.filter((e) => e !== errorMultipleChoiceChoices);
      }

      setNewQuestion({
        ...newQuestion,
        multipleChoiceAnswers: newMultipleChoiceAnswers,
        errors: newErrors,
      });
    }
  };

  const handleAnswerChange = (event) => {
    const { value, checked } = event.target;
    let ans = [...newQuestion.multipleChoiceAnswers.map((a) => a.option)];

    if (checked) {
      if (!ans.includes(value)) {
        ans.push(value);
      }
    } else {
      ans = ans.filter((item) => item !== value);
    }
    setQuestionAnswers(ans);
  };

  const sortChoices = (choices) => {
    return choices.sort((a, b) => a.option.localeCompare(b.option));
  };

  const reassignOptions = (choices) => {
    return choices.map((choice, index) => {
      const newOption = String.fromCharCode(65 + index); // 65 is the char code for 'A'
      return { option: newOption, value: choice.value };
    });
  };

  const handleChoiceChange = (option, newValue) => {
    const cleanValue = newValue.replace('"', '');
    const newMultipleChoiceChoices = newQuestion.multipleChoiceChoices.map(
      (c) => (c.option === option ? { ...c, value: cleanValue } : c)
    );
    const newMultipleChoiceAnswers = newQuestion.multipleChoiceAnswers.map(
      (c) => (c.option === option ? { ...c, value: cleanValue } : c)
    );

    let newErrors = [...newQuestion.errors];
    const errorMultipleChoiceChoices = 'Choices cannot be blank. ';
    if (cleanValue.trim() === '') {
      if (!newErrors.includes(errorMultipleChoiceChoices)) {
        newErrors.push(errorMultipleChoiceChoices);
      }
    } else {
      newErrors = newErrors.filter((e) => e !== errorMultipleChoiceChoices);
    }

    setNewQuestion({
      ...newQuestion,
      multipleChoiceChoices: newMultipleChoiceChoices,
      multipleChoiceAnswers: newMultipleChoiceAnswers,
      errors: newErrors,
    });
  };

  const handleAddChoice = () => {
    const currentOptions = newQuestion.multipleChoiceChoices.map(
      (choice) => choice.option
    );
    let nextOptionCharCode = 65; // ASCII code for 'A'

    while (currentOptions.includes(String.fromCharCode(nextOptionCharCode))) {
      nextOptionCharCode += 1;
    }
    const nextOption = String.fromCharCode(nextOptionCharCode);
    const newMultipleChoiceChoices = [
      ...newQuestion.multipleChoiceChoices,
      { option: nextOption, value: nextOption },
    ].sort((a, b) => a.option.localeCompare(b.option));

    let newErrors = [...newQuestion.errors];
    const errorMultipleChoiceChoices = 'Choices are required. ';
    if (newMultipleChoiceChoices.length === 0) {
      if (!newErrors.includes(errorMultipleChoiceChoices)) {
        newErrors.push(errorMultipleChoiceChoices);
      }
    } else {
      newErrors = newErrors.filter((e) => e !== errorMultipleChoiceChoices);
    }

    setNewQuestion({
      ...newQuestion,
      multipleChoiceChoices: newMultipleChoiceChoices,
      errors: newErrors,
    });
  };

  const handleRemoveChoice = (optionToRemove) => {
    const newMultipleChoiceChoices = reassignOptions(
      sortChoices(
        newQuestion.multipleChoiceChoices.filter(
          (choice) => choice.option !== optionToRemove
        )
      )
    );
    const newMultipleChoiceAnswers = sortChoices(
      newQuestion.multipleChoiceAnswers.filter(
        (choice) => choice.option !== optionToRemove
      )
    );
    let newErrors = [...newQuestion.errors];
    const errorMultipleChoiceChoices = 'Choices are required. ';
    if (newMultipleChoiceChoices.length === 0) {
      if (!newErrors.includes(errorMultipleChoiceChoices)) {
        newErrors.push(errorMultipleChoiceChoices);
      }
    } else {
      newErrors = newErrors.filter((e) => e !== errorMultipleChoiceChoices);
    }

    setNewQuestion({
      ...newQuestion,
      multipleChoiceChoices: newMultipleChoiceChoices,
      multipleChoiceAnswers: newMultipleChoiceAnswers,
      errors: newErrors,
    });
  };

  const handleExplanationChange = (newValue) => {
    setNewQuestion({
      ...newQuestion,
      explanation: newValue,
    });
  };
  const handleQuestionChange = (event, editor) => {
    const data = editor.getData();
    let newErrors = [...newQuestion.errors];
    const questionError = 'Question cannot be blank. ';
    if (data.trim() === '') {
      if (!newErrors.includes(questionError)) {
        newErrors.push(questionError);
      }
    } else {
      newErrors = newErrors.filter((e) => e !== questionError);
    }

    setNewQuestion({
      ...newQuestion,
      questionHtml: data,
      errors: newErrors,
    });
  };

  const handleMarkChange = (newValue) => {
    let mark = 0;
    if (newValue !== '' && !Number.isNaN(newValue)) {
      mark = parseFloat(newValue);
    }
    setNewQuestion({
      ...newQuestion,
      mark,
    });
  };

  return (
    <Box>
      <Flex align="center" mb={4}>
        {newQuestion.errors.length > 0 && (
          <Text color="red">{newQuestion.errors}</Text>
        )}
      </Flex>
      <FormLabel>Question</FormLabel>
      <CKEditor
        editor={ClassicEditor}
        config={{
          plugins: [Essentials, Bold, Italic, Paragraph, Underline],
          toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline'],
        }}
        data={newQuestion.questionHtml}
        onChange={handleQuestionChange}
      />
      <Flex align="center" mt={4}>
        <Box mr={3}>
          <Text>Mark</Text>
        </Box>
        <Box>
          <NumberInput
            min={0}
            max={100}
            w="100px"
            onChange={(e) => handleMarkChange(e)}
            value={newQuestion.mark}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Spacer />
        <Box>
          <Checkbox
            size="lg"
            mr={5}
            onChange={(e) =>
              setNewQuestion({
                ...newQuestion,
                allowPartialPoints: e.target.checked,
              })
            }
            isChecked={newQuestion.allowPartialPoints}
          >
            Allow partial points
          </Checkbox>
        </Box>
      </Flex>
      <Box mt={4}>
        {newQuestion.multipleChoiceChoices.map((choice) => (
          <Stack
            key={choice.option}
            direction={['column', 'row']}
            spacing="24px"
            align="center"
            m={2}
          >
            <Box>
              <Text fontSize="lg">{choice.option}</Text>
            </Box>
            <Box w="50%">
              <Input
                value={choice.value}
                onChange={(e) =>
                  handleChoiceChange(choice.option, e.target.value)
                }
              />
            </Box>
            <Box>
              <Checkbox
                size="lg"
                value={choice.option}
                isChecked={newQuestion.multipleChoiceAnswers
                  .map((a) => a.option)
                  .includes(choice.option)}
                onChange={handleAnswerChange}
              />
            </Box>
            <Box>
              <IconButton
                isRound
                size="sm"
                icon={<IoMdClose />}
                onClick={() => handleRemoveChoice(choice.option)}
              />
            </Box>
          </Stack>
        ))}

        <Box mt={4}>
          <Button
            colorScheme="blue"
            size="sm"
            variant="outline"
            onClick={() => handleAddChoice()}
          >
            Add Choice
          </Button>
        </Box>
      </Box>
      <Box mt={4}>
        <Text fontSize="lg">Explanation</Text>
        <Textarea
          value={newQuestion.explanation}
          onChange={(e) => handleExplanationChange(e.target.value)}
        />
      </Box>
    </Box>
  );
}

MultipleChoice.propTypes = {
  question: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default MultipleChoice;
