import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Checkbox,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spacer,
  IconButton,
  Divider,
} from '@chakra-ui/react';

import { DragHandleIcon } from '@chakra-ui/icons';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';

import { ReducerStatus } from 'utils/constants';
import { arrayOfString } from 'utils/jsonParser';

import SubQuestForm from 'components/subQuest/SubQuestForm';
import FormikFormControl from 'components/common/forms/FormikFormControl';
import ResultModal from 'components/common/modals/ResultModal';
import MultipleChoice from 'components/question/multipleChoice';

import { subquestActions } from 'store/modules/subquest/subquest-slice';
import { companionActions } from 'companion/store/companion-slice';
import {
  createQuizSubQuest,
  createQuizSubQuestQuestions,
} from 'store/modules/subquest/subquest-action';

function CreateQuizMd() {
  const dispatch = useDispatch();
  const formikForm = useRef();

  const [triggerSubQuestForm, setTriggerSubQuestForm] = useState(0);
  const [isSubQuestFormValid, setIsSubQuestFormValid] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [subQuestFormInputs, setSubQuestFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
    isPublished: '',
    tags: [],
    groups: [],
  });

  const [formInputs, setFormInputs] = useState({
    scoreType: 'Actual Score',
    hasTimeLimit: true,
    timeLimit: 5,
    passingScore: 1,
    isShuffled: true,
    showAnswers: true,
    showResult: true,
    unlimitedAttempts: true,
    numberOfAttempts: 3,
    hasIntervalBeforeNextAttempt: false,
    intervalBeforeNextAttempt: 60,
    fetchFromQuestionBank: false,
    totalMark: 0,
  });

  const [timeBeforeNextAttempt, setTimeBeforeNextAttempt] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const questionTypes = [{ Name: 'Multiple Choice', Id: 1 }];

  const createQuiz = useSelector((state) => state.subquest.createQuiz);
  const quest = useSelector((state) => state.subquest.quest);
  const createQuizQuestions = useSelector(
    (state) => state.subquest.createQuizQuestions
  );
  const quizJson = useSelector((state) => state.companion.quizJson);

  const [questions, setQuestions] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const handleToggleUpdateModal = (modalstate) => {
    dispatch(subquestActions.toggleQuizCreateModal(modalstate));
  };

  useEffect(() => {
    if (quizJson != null) {
      dispatch(companionActions.setModal(false));
      dispatch(subquestActions.toggleQuizCreateModal(true));
      const newQuestions = quizJson.questions.map((newQuestion, index) => ({
        ...newQuestion,
        id: index,
        questionHtml: newQuestion.questionHtml,
        questionTypeId: newQuestion.questionTypeId,
        multipleChoiceChoices: newQuestion.multipleChoiceChoices,
        multipleChoiceAnswers: newQuestion.multipleChoiceAnswers,
        trueOrFalseAnswer: null,
        explanation: '',
        mark: 1,
        allowPartialPoints: false,
        errors: [],
      }));
      setSubQuestFormInputs((prev) => ({
        ...prev,
        name: quizJson.name,
        objective: quizJson.description,
      }));
      setQuestions(newQuestions);
      setTabIndex(0);
    }
  }, [quizJson, dispatch]);

  const handleAddQuestion = () => {
    const newQuestion = {
      id: questions.length + 1,
      questionHtml: '',
      questionTypeId: 1,
      multipleChoiceChoices: [
        { option: 'A', value: 'A' },
        { option: 'B', value: 'B' },
      ],
      multipleChoiceAnswers: [{ option: 'A', value: 'A' }],
      trueOrFalseAnswer: true,
      explanation: '',
      mark: 1,
      allowPartialPoints: false,
      errors: ['Question cannot be blank. '],
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleTabsChange = (index) => {
    if (index < 0 || index > 2) return;
    setTabIndex(index);
  };

  const handleUpdateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = updatedQuestion;
    setQuestions(updatedQuestions);
  };

  const handleQuestionTypeChange = (index, question, newQuestionTypeId) => {
    const updatedQuestion = {
      ...question,
      questionTypeId: parseInt(newQuestionTypeId, 10),
    };
    handleUpdateQuestion(index, updatedQuestion);
  };

  const handleRemoveQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const formValidation = Yup.object({
    scoreType: Yup.string().required('Required'),
    passingScore: Yup.number().required('Required'),
    timeLimit: Yup.number().required('Required'),
  });

  useEffect(() => {
    if (quest?.ownerGroups) {
      setSubQuestFormInputs((prev) => ({
        ...prev,
        groups: arrayOfString(quest?.ownerGroups),
      }));
    }
  }, [quest?.ownerGroups]);

  const convertTimeBeforeNextAttemptToSeconds = ({ days, hours, minutes }) => {
    const daysInSeconds = days * 24 * 60 * 60;
    const hoursInSeconds = hours * 60 * 60;
    const minutesInSeconds = minutes * 60;
    return daysInSeconds + hoursInSeconds + minutesInSeconds;
  };

  const handleSubmitForm = async (values) => {
    if (isSubQuestFormValid === false) return;

    setFormInputs({ ...values });
    let interval = values.intervalBeforeNextAttempt;
    let time = values.timeLimit;

    if (values.hasIntervalBeforeNextAttempt) {
      interval = convertTimeBeforeNextAttemptToSeconds(timeBeforeNextAttempt);
    }
    if (values.hasTimeLimit === false) {
      time = 1;
    }

    if (questions.length > 0) {
      const mergedObject = {
        ...formInputs,
        ...subQuestFormInputs,
        timeLimit: time,
        intervalBeforeNextAttempt: interval,
        ownerGroups: subQuestFormInputs.groups,
        isPublished: subQuestFormInputs.isPublished === 'true',
        totalMark: questions.reduce((sum, question) => sum + question.mark, 0),
      };

      const totalErrorCount = questions.reduce((acc, entry) => {
        return acc + entry.errors.length;
      }, 0);
      if (totalErrorCount === 0) {
        const newQuizSubQuest = await dispatch(
          createQuizSubQuest({ questId: quest.id, quizSubQuest: mergedObject })
        );
        if (formInputs.fetchFromQuestionBank === false) {
          const createdSubQuestQuizId =
            newQuizSubQuest.payload?.subQuest?.subQuest?.subQuestQuiz?.id;
          questions.forEach((question, index) => {
            const updatedQuestion = question;
            updatedQuestion.SubQuestQuizId = createdSubQuestQuizId;
            updatedQuestion.position = index;
            handleUpdateQuestion(index, updatedQuestion);
          });
          dispatch(createQuizSubQuestQuestions({ questions }));
        }
      }
    }
  };

  const handleTriggerSubmit = () => {
    setTriggerSubQuestForm((trigger) => trigger + 1);
    setIsFormSubmit(true);
  };

  useEffect(() => {
    if (isFormSubmit && isSubQuestFormValid) {
      formikForm?.current?.submitForm();
      setIsFormSubmit(false);
    }
  }, [isFormSubmit, isSubQuestFormValid, setIsFormSubmit]);

  const handleResult = () => {
    if (createQuiz.status === ReducerStatus.SUCCEEDED) {
      setFormInputs({
        scoreType: 'Actual Score',
        hasTimeLimit: true,
        timeLimit: 5,
        passingScore: 1,
        isShuffled: true,
        showAnswers: true,
        showResult: true,
        unlimitedAttempts: true,
        numberOfAttempts: 3,
        hasIntervalBeforeNextAttempt: false,
        intervalBeforeNextAttempt: 60,
        fetchFromQuestionBank: false,
        totalMark: 0,
      });
      setSubQuestFormInputs({
        name: '',
        objective: '',
        code: '',
        isPublished: '',
        tags: [],
        emails: [],
      });
    }
    setTabIndex(0);
    setTriggerSubQuestForm(-1);
    setQuestions([]);
    dispatch(subquestActions.resetCreateQuiz());
  };

  return (
    <Box>
      {(createQuiz.status === ReducerStatus.SUCCEEDED &&
        createQuizQuestions.status === ReducerStatus.SUCCEEDED) ||
      createQuiz.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={createQuiz.modal}
          onSubmit={handleResult}
          onClose={() => {
            handleToggleUpdateModal(false);
          }}
          isError={createQuiz.status === ReducerStatus.FAILED}
          title={
            createQuiz.status === ReducerStatus.SUCCEEDED &&
            createQuizQuestions.status === ReducerStatus.SUCCEEDED
              ? 'Quiz Sub-Quest Created'
              : null
          }
          message={
            createQuiz.status === ReducerStatus.SUCCEEDED &&
            createQuizQuestions.status === ReducerStatus.SUCCEEDED
              ? 'The quiz sub-quest has been created successfully.'
              : createQuiz.error?.data?.title || 'Server Error'
          }
          errorObject={createQuiz.error?.data?.errors}
        />
      ) : (
        <Modal
          size="5xl"
          isOpen={createQuiz.modal}
          onClose={() => {
            handleToggleUpdateModal(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create Quiz Sub-Quest</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                handleResult();
              }}
            />
            <ModalBody my={3}>
              <Tabs
                variant="enclosed"
                index={tabIndex}
                onChange={handleTabsChange}
              >
                <TabList>
                  <Tab>Sub Quest</Tab>
                  <Tab>Quiz</Tab>
                  <Tab>Questions</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <SubQuestForm
                      questName={quest?.name}
                      groups={quest?.ownerGroups}
                      subQuestFormInputs={subQuestFormInputs}
                      triggerSubQuestForm={triggerSubQuestForm}
                      setSubQuestFormInputs={setSubQuestFormInputs}
                      setIsSubQuestFormValid={setIsSubQuestFormValid}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Formik
                      enableReinitialize
                      innerRef={formikForm}
                      initialValues={formInputs}
                      onSubmit={(values) => {
                        handleSubmitForm(values);
                      }}
                      validationSchema={formValidation}
                    >
                      {() => (
                        <Box>
                          <Form>
                            <Flex>
                              <Box flex="1" mr={5}>
                                <Flex mt={5}>
                                  <Box>
                                    <Field name="isShuffled">
                                      {({ field, form }) => (
                                        <FormikFormControl
                                          errors={form.errors.isShuffled}
                                          touched={form.touched.isShuffled}
                                          form={form}
                                          label=""
                                          helperText=""
                                        >
                                          <Checkbox
                                            size="lg"
                                            mr={5}
                                            isChecked={field.value}
                                            onChange={(e) =>
                                              setFormInputs({
                                                ...formInputs,
                                                isShuffled: e.target.checked,
                                              })
                                            }
                                          >
                                            Shuffle Questions
                                          </Checkbox>
                                          <Text fontSize="sm" color="gray">
                                            Randomize the order of questions
                                            each time the quiz is taken.
                                          </Text>
                                        </FormikFormControl>
                                      )}
                                    </Field>
                                  </Box>
                                </Flex>
                                <Flex align="center" mt={4}>
                                  <Box w="50%">
                                    <Field name="hasTimeLimit">
                                      {({ field, form }) => (
                                        <FormikFormControl
                                          form={form}
                                          label=""
                                          helperText=""
                                          errors={form.errors.hasTimeLimit}
                                          touched={form.touched.hasTimeLimit}
                                        >
                                          <Checkbox
                                            size="lg"
                                            mr={5}
                                            isChecked={field.value}
                                            onChange={(e) =>
                                              setFormInputs({
                                                ...formInputs,
                                                hasTimeLimit: e.target.checked,
                                              })
                                            }
                                          >
                                            Time Limit
                                          </Checkbox>
                                        </FormikFormControl>
                                      )}
                                    </Field>
                                  </Box>
                                  <Box>
                                    {formInputs.hasTimeLimit === true && (
                                      <Field name="timeLimit">
                                        {({ field, form }) => (
                                          <FormikFormControl
                                            errors={form.errors.timeLimit}
                                            touched={form.touched.timeLimit}
                                            form={form}
                                            label=""
                                            helperText=""
                                          >
                                            <NumberInput
                                              min={0}
                                              max={100}
                                              w="100px"
                                              onChange={(value) =>
                                                setFormInputs({
                                                  ...formInputs,
                                                  timeLimit:
                                                    value === ''
                                                      ? 0
                                                      : parseInt(value, 10),
                                                })
                                              }
                                              value={field.value}
                                            >
                                              <NumberInputField />
                                              <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                              </NumberInputStepper>
                                            </NumberInput>
                                          </FormikFormControl>
                                        )}
                                      </Field>
                                    )}
                                  </Box>
                                  {formInputs.hasTimeLimit === true && (
                                    <Box ml={2}>minutes</Box>
                                  )}
                                </Flex>
                                <Flex mt={-2}>
                                  <Text fontSize="sm" color="gray">
                                    Check this box if you want to impose a time
                                    limit on the quiz
                                  </Text>
                                </Flex>
                                <Flex align="center" mt={5}>
                                  <Box w="50%">
                                    <Field name="unlimitedAttempts">
                                      {({ field, form }) => (
                                        <FormikFormControl
                                          errors={form.errors.unlimitedAttempts}
                                          touched={
                                            form.touched.unlimitedAttempts
                                          }
                                          form={form}
                                          label=""
                                          helperText=""
                                        >
                                          <Checkbox
                                            size="lg"
                                            mr={5}
                                            isChecked={field.value}
                                            onChange={(e) =>
                                              setFormInputs({
                                                ...formInputs,
                                                unlimitedAttempts:
                                                  e.target.checked,
                                              })
                                            }
                                          >
                                            Unlimited Attempts
                                          </Checkbox>
                                        </FormikFormControl>
                                      )}
                                    </Field>
                                  </Box>
                                  <Box>
                                    {formInputs.unlimitedAttempts === false && (
                                      <Field name="numberOfAttempts">
                                        {({ field, form }) => (
                                          <FormikFormControl
                                            errors={
                                              form.errors.numberOfAttempts
                                            }
                                            touched={
                                              form.touched.numberOfAttempts
                                            }
                                            form={form}
                                            label=""
                                            helperText=""
                                          >
                                            <NumberInput
                                              min={0}
                                              w="100px"
                                              onChange={(value) =>
                                                setFormInputs({
                                                  ...formInputs,
                                                  numberOfAttempts:
                                                    value === ''
                                                      ? 0
                                                      : parseInt(value, 10),
                                                })
                                              }
                                              value={field.value}
                                            >
                                              <NumberInputField {...field} />
                                              <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                              </NumberInputStepper>
                                            </NumberInput>
                                          </FormikFormControl>
                                        )}
                                      </Field>
                                    )}
                                  </Box>
                                  {formInputs.unlimitedAttempts === false && (
                                    <Box ml={2}>attempts</Box>
                                  )}
                                </Flex>
                                <Flex mt={-2}>
                                  <Text fontSize="sm" color="gray">
                                    Allow participants to retake the quiz an
                                    unlimited number of times.
                                  </Text>
                                </Flex>
                                <Flex mt={5}>
                                  <Box>
                                    <Field name="hasIntervalBeforeNextAttempt">
                                      {({ field, form }) => (
                                        <FormikFormControl
                                          errors={
                                            form.errors
                                              .hasIntervalBeforeNextAttempt
                                          }
                                          touched={
                                            form.touched
                                              .hasIntervalBeforeNextAttempt
                                          }
                                          form={form}
                                          label=""
                                          helperText=""
                                        >
                                          <Checkbox
                                            size="lg"
                                            mr={5}
                                            isChecked={field.value}
                                            onChange={(e) =>
                                              setFormInputs({
                                                ...formInputs,
                                                hasIntervalBeforeNextAttempt:
                                                  e.target.checked,
                                              })
                                            }
                                          >
                                            Interval Before Next Attempt
                                          </Checkbox>
                                        </FormikFormControl>
                                      )}
                                    </Field>
                                  </Box>
                                  <Box>
                                    {formInputs.hasIntervalBeforeNextAttempt ===
                                      true && (
                                      <Flex align="center">
                                        <Box>
                                          <Flex>
                                            <Box w="70px" mr={2}>
                                              <Text
                                                fontWeight="medium"
                                                fontSize="sm"
                                              >
                                                days
                                              </Text>
                                              <NumberInput
                                                min={0}
                                                size="md"
                                                onChange={(value) =>
                                                  setTimeBeforeNextAttempt({
                                                    ...timeBeforeNextAttempt,
                                                    days: value,
                                                  })
                                                }
                                                value={
                                                  timeBeforeNextAttempt.days
                                                }
                                              >
                                                <NumberInputField />
                                                <NumberInputStepper>
                                                  <NumberIncrementStepper />
                                                  <NumberDecrementStepper />
                                                </NumberInputStepper>
                                              </NumberInput>
                                            </Box>
                                            <Box w="70px" mr={2}>
                                              <Text
                                                fontWeight="medium"
                                                fontSize="sm"
                                              >
                                                hours
                                              </Text>
                                              <NumberInput
                                                min={0}
                                                max={23}
                                                size="md"
                                                onChange={(value) =>
                                                  setTimeBeforeNextAttempt({
                                                    ...timeBeforeNextAttempt,
                                                    hours: value,
                                                  })
                                                }
                                                value={
                                                  timeBeforeNextAttempt.hours
                                                }
                                              >
                                                <NumberInputField />
                                                <NumberInputStepper>
                                                  <NumberIncrementStepper />
                                                  <NumberDecrementStepper />
                                                </NumberInputStepper>
                                              </NumberInput>
                                            </Box>
                                            <Box w="70px" mr={2}>
                                              <Text
                                                fontWeight="medium"
                                                fontSize="sm"
                                              >
                                                minutes
                                              </Text>
                                              <NumberInput
                                                defaultValue={0}
                                                min={0}
                                                max={59}
                                                size="md"
                                                onChange={(value) =>
                                                  setTimeBeforeNextAttempt({
                                                    ...timeBeforeNextAttempt,
                                                    minutes: value,
                                                  })
                                                }
                                                value={
                                                  timeBeforeNextAttempt.minutes
                                                }
                                              >
                                                <NumberInputField />
                                                <NumberInputStepper>
                                                  <NumberIncrementStepper />
                                                  <NumberDecrementStepper />
                                                </NumberInputStepper>
                                              </NumberInput>
                                            </Box>
                                          </Flex>
                                        </Box>
                                      </Flex>
                                    )}
                                  </Box>
                                </Flex>
                                <Flex>
                                  <Text fontSize="sm" color="gray">
                                    Set a waiting period before participants can
                                    retake the quiz.
                                  </Text>
                                </Flex>
                              </Box>
                              <Box flex="1">
                                <Flex>
                                  <Box flex="1" mr={5}>
                                    <Field name="scoreType">
                                      {({ field, form }) => (
                                        <FormikFormControl
                                          label="Score Type"
                                          helperText="Compute scores as a percentage or as an actual score."
                                          errors={form.errors.scoreType}
                                          touched={form.touched.scoreType}
                                          form={form}
                                        >
                                          <Select
                                            onChange={(e) =>
                                              setFormInputs({
                                                ...formInputs,
                                                scoreType: e.target.value,
                                              })
                                            }
                                            value={field.value}
                                          >
                                            <option value="Actual Score">
                                              Actual Score
                                            </option>
                                            <option value="Percentage">
                                              Percentage
                                            </option>
                                          </Select>
                                        </FormikFormControl>
                                      )}
                                    </Field>
                                  </Box>
                                  <Box flex="1" mr={5}>
                                    <Field name="passingScore">
                                      {({ field, form }) => (
                                        <FormikFormControl
                                          label={
                                            formInputs.scoreType ===
                                            'Percentage'
                                              ? 'Passing Score (%)'
                                              : 'Passing Score'
                                          }
                                          helperText="Define the score needed to pass the quiz based on the Score Setting."
                                          errors={form.errors.passingScore}
                                          touched={form.touched.passingScore}
                                          form={form}
                                        >
                                          <NumberInput
                                            min={0}
                                            max={100}
                                            w="100px"
                                            onChange={(value) =>
                                              setFormInputs({
                                                ...formInputs,
                                                passingScore:
                                                  value === ''
                                                    ? 0
                                                    : parseInt(value, 10),
                                              })
                                            }
                                            value={field.value}
                                          >
                                            <NumberInputField {...field} />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormikFormControl>
                                      )}
                                    </Field>
                                  </Box>
                                </Flex>
                                <Flex mt={5}>
                                  <Box w="50%">
                                    <Field name="showResult">
                                      {({ field, form }) => (
                                        <FormikFormControl
                                          errors={form.errors.showResult}
                                          touched={form.touched.showResult}
                                          form={form}
                                          label=""
                                          helperText=""
                                        >
                                          <Checkbox
                                            size="lg"
                                            mr={5}
                                            isChecked={field.value}
                                            onChange={(e) =>
                                              setFormInputs({
                                                ...formInputs,
                                                showResult: e.target.checked,
                                              })
                                            }
                                          >
                                            Show Result
                                          </Checkbox>
                                        </FormikFormControl>
                                      )}
                                    </Field>
                                    <Text fontSize="sm" color="gray">
                                      Display scores right after the quiz for
                                      instant feedback.
                                    </Text>
                                  </Box>
                                  <Box w="50%">
                                    <Field name="showAnswers">
                                      {({ field, form }) => (
                                        <FormikFormControl
                                          errors={form.errors.showAnswers}
                                          touched={form.touched.showAnswers}
                                          form={form}
                                          label=""
                                          helperText=""
                                        >
                                          <Checkbox
                                            size="lg"
                                            mr={5}
                                            isChecked={field.value}
                                            onChange={(e) =>
                                              setFormInputs({
                                                ...formInputs,
                                                showAnswers: e.target.checked,
                                              })
                                            }
                                          >
                                            Show Answers
                                          </Checkbox>
                                        </FormikFormControl>
                                      )}
                                    </Field>
                                    <Text fontSize="sm" color="gray">
                                      Allow participants to view correct answers
                                      after the quiz.
                                    </Text>
                                  </Box>
                                </Flex>
                              </Box>
                            </Flex>
                            <Flex>
                              <Spacer />
                            </Flex>
                          </Form>
                        </Box>
                      )}
                    </Formik>
                  </TabPanel>
                  <TabPanel>
                    <Box>
                      <Flex align="center">
                        <Spacer />
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          mr={3}
                          onClick={handleAddQuestion}
                        >
                          Add Question
                        </Button>
                      </Flex>
                    </Box>
                    {questions.map((question, index) => (
                      <Box p={4} key={question.id}>
                        <Box mb={1} borderWidth="1px" borderRadius="md">
                          <Box w="100%">
                            <Flex align="center">
                              <IconButton
                                variant="plain"
                                color="teal"
                                aria-label="Re-order quest"
                                icon={<DragHandleIcon />}
                              />

                              <Box w="40%" p={4}>
                                <Select
                                  value={question.questionTypeId}
                                  onChange={(e) =>
                                    handleQuestionTypeChange(
                                      index,
                                      question,
                                      e.target.value
                                    )
                                  }
                                >
                                  {questionTypes.map((type) => (
                                    <option value={type.Id} key={type.Id}>
                                      {type.Name}
                                    </option>
                                  ))}
                                </Select>
                              </Box>
                              <Spacer />
                              <Box p={4}>
                                <IconButton
                                  isRound
                                  size="sm"
                                  icon={<IoMdClose />}
                                  onClick={() => handleRemoveQuestion(index)}
                                />
                              </Box>
                            </Flex>
                          </Box>
                          <Divider />
                          <Box p={4}>
                            {question.questionTypeId === 1 && (
                              <MultipleChoice
                                question={question}
                                onUpdate={(updatedQuestion) =>
                                  handleUpdateQuestion(index, updatedQuestion)
                                }
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
            <Divider />
            <ModalFooter>
              <Flex>
                <Button
                  variant="outline"
                  colorScheme="blue"
                  mr={3}
                  onClose={() => {
                    handleToggleUpdateModal(false);
                  }}
                  isLoading={createQuiz.status === ReducerStatus.LOADING}
                >
                  Close
                </Button>
                <Button
                  type="button"
                  colorScheme="red"
                  isDisabled={tabIndex === 0}
                  isLoading={
                    createQuiz.status === ReducerStatus.LOADING ||
                    createQuizQuestions.status === ReducerStatus.LOADING
                  }
                  px={10}
                  mr={4}
                  onClick={() => {
                    handleTabsChange(tabIndex - 1);
                  }}
                  variant="outline"
                >
                  Back
                </Button>
                <Button
                  type="button"
                  colorScheme="red"
                  isDisabled={tabIndex === 2}
                  isLoading={
                    createQuiz.status === ReducerStatus.LOADING ||
                    createQuizQuestions.status === ReducerStatus.LOADING
                  }
                  px={10}
                  mr={4}
                  onClick={() => {
                    handleTabsChange(tabIndex + 1);
                  }}
                  variant="outline"
                >
                  Next
                </Button>
                <Button
                  isLoading={
                    createQuiz.status === ReducerStatus.LOADING ||
                    createQuizQuestions.status === ReducerStatus.LOADING
                  }
                  onClick={handleTriggerSubmit}
                  colorScheme="red"
                  px={10}
                >
                  Submit
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}
export default CreateQuizMd;
