import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  IconButton,
  Progress,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect, useCallback } from 'react';
import QuestionItem from 'components/question/QuestionItem';
import { MdFlag } from 'react-icons/md';
import { generateFinalSignature } from 'store/modules/progression/progression-actions';
import axios from 'axios';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import ErrorModal from 'components/common/modals/ResultModal';
import { useDispatch } from 'react-redux';
import { progressionActions } from 'store/modules/progression/progression-slice';
import useToastNotif from 'hooks/useToastNotif';
import { TOAST_INFO } from 'utils/constants/toast';
import QuizStart from './quizzes/QuizStart';
import QuizResult from './quizzes/QuizResult';

function QuizSubQuest({ course, quest, subQuest, code }) {
  const {
    isOpen: isOpenConfirmation,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();

  const {
    isOpen: isOpenError,
    onOpen: onOpenError,
    onClose: onCloseError,
  } = useDisclosure();

  const { subQuestQuiz } = subQuest;
  const {
    scoreType,
    hasTimeLimit,
    timeLimit,
    totalMark,
    passingScore,
    unlimitedAttempts,
    questions,
  } = subQuestQuiz;

  const [position, setPosition] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [time, setTime] = useState(0);
  const [flagQuestions, setFlagQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [isStarted, setIsStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { generateToastNotif } = useToastNotif();

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
    return () => {};
  }, [time]);

  useEffect(() => {
    if (isStarted && (time === 30 || time === 60)) {
      generateToastNotif(
        TOAST_INFO,
        'Time is running out',
        `Only ${time} seconds remaining. The quiz will be automatically submitted once time runs out.`,
        true,
        false
      );
    }
  }, [isStarted, time, generateToastNotif]);

  const handleNextQuestion = () => {
    setPosition((prevPos) => prevPos + 1);
  };

  const handlePrevQuestion = () => {
    setPosition((prevPos) => prevPos - 1);
  };

  const navigateToQuestion = (newPos) => {
    setPosition(newPos);
  };

  const handleSelectAnswer = (question, values) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.questionId === question.id
      );
      if (existingAnswerIndex >= 0) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex] = {
          questionId: question.id,
          values,
        };
        return updatedAnswers;
      }
      return [...prevAnswers, { questionId: question.id, values }];
    });
  };

  const findSelectedAnswer = (questionId) => {
    const currentAnswer = answers.find(
      (answer) => answer.questionId === questionId
    );
    return currentAnswer ? currentAnswer.values : [];
  };

  const isQuestionAnswered = (questionId) => {
    const currentAnswer = answers.find(
      (answer) => answer.questionId === questionId
    );
    if (!currentAnswer) return false;

    return currentAnswer.values.length > 0;
  };

  const handleFlagQuestion = (questionId) => {
    const flagQuestion = flagQuestions.find((fq) => fq === questionId);
    if (flagQuestion) {
      setFlagQuestions((prevFlagQuestions) =>
        prevFlagQuestions.filter((i) => i !== questionId)
      );
    } else {
      setFlagQuestions((prevFlagQuestions) => [
        ...prevFlagQuestions,
        questionId,
      ]);
    }
  };

  const handleRestart = () => {
    setResult(null);
    setPosition(0);
    setIsStarted(false);
    setAnswers([]);
  };

  const handleStartQuiz = (timeSecondsLeft) => {
    setIsStarted(true);
    if (timeLimit > 0 && hasTimeLimit && timeSecondsLeft) {
      setTime(timeSecondsLeft);
    }
  };

  const handleSubmitQuiz = useCallback(async () => {
    setIsLoading(true);
    const payload = {
      CourseId: course.id,
      QuestId: quest.id,
      SubQuestId: subQuest.id,
    };

    try {
      const finalSignature = await generateFinalSignature(payload, code);

      payload.Answers = answers;

      const response = await axios.post('/quizzes/finish', payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-signature': finalSignature,
        },
      });

      const { progression } = response.data;

      dispatch(progressionActions.updateProgression(progression));
      setResult(response.data);
      setIsStarted(false);
      onCloseConfirmation();
    } catch (e) {
      onOpenError();
      setError({
        status: e.response?.status,
        data: e.response?.data,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    course.id,
    quest.id,
    subQuest.id,
    code,
    answers,
    dispatch,
    onCloseConfirmation,
    onOpenError,
  ]);

  useEffect(() => {
    if (time === 0 && isStarted && hasTimeLimit) {
      handleSubmitQuiz();
    }
  }, [time, isStarted, hasTimeLimit, handleSubmitQuiz]);
  const borderColor = useColorModeValue('gray.200', 'teal');
  const buttonColor = useColorModeValue('red', 'teal');

  return (
    <Box
      borderRadius="md"
      boxShadow="sm"
      border="1px"
      borderColor={borderColor}
      h="65vh"
      w="100%"
    >
      <ConfirmationModal
        isVisible={isOpenConfirmation}
        isLoading={isLoading}
        title="Confirmation Required"
        description="Are you sure you want to submit your quiz answers? Please note that if the quiz has no unlimited attempts, you will not be able to re-take this quiz."
        onSubmit={handleSubmitQuiz}
        onCancel={onCloseConfirmation}
      />

      <ErrorModal
        isOpen={isOpenError}
        isError={isOpenError}
        message={error?.data?.title}
        errorObject={error?.data?.errors}
        onSubmit={onCloseError}
      />

      <Flex h="100%" direction="column">
        <Flex px={4}>
          <Box py={4}>
            <Text as="b">{subQuest.name}</Text>
          </Box>

          <Spacer />
          {hasTimeLimit && isStarted && (
            <Box my={2} borderRadius="md" px={2}>
              <Flex gap={3}>
                <Center>
                  <Text fontSize="sm" fontWeight={600}>
                    Time Left:
                  </Text>
                </Center>

                <Box bg="grey" borderRadius="md" m={1} px={3} py={1}>
                  <Text color="white" fontSize="sm" fontWeight={500}>
                    {String(Math.floor(time / 60)).padStart(2, '0')}:
                    {String(time % 60).padStart(2, '0')}
                    {isLoading && (
                      <>
                        &nbsp;
                        <Spinner size="sm" />
                      </>
                    )}
                  </Text>
                </Box>
              </Flex>
            </Box>
          )}
        </Flex>

        <Progress
          colorScheme={useColorModeValue('red', 'teal')}
          size="xs"
          value={answers.filter((a) => a.values.length > 0).length}
          max={questions.length}
        />
        <Box h="100%" p={4} overflowY="scroll">
          {/* Start Quiz */}
          {!isStarted && !result && subQuestQuiz && (
            <QuizStart
              courseId={course.id}
              questId={quest.id}
              subQuestId={subQuest.id}
              hasTimeLimit={hasTimeLimit}
              unlimitedAttempts={unlimitedAttempts}
              timeLimit={timeLimit}
              totalMark={totalMark}
              passingScore={passingScore}
              scoreType={scoreType}
              handleStartQuiz={handleStartQuiz}
            />
          )}

          {/* Result Quiz */}
          {!isStarted && result && (
            <QuizResult
              isShowResult={result.isShowResult}
              isShowAnswer={result.isShowAnswer}
              score={result.score}
              totalMark={result.totalMark}
              passingScore={result.passingScore}
              questionnaires={result.questionnaires}
              handleRestart={handleRestart}
              scoreType={scoreType}
            />
          )}

          {isStarted && questions && questions.length > 1 && (
            <Center minH="53px">
              <Flex wrap="wrap" gap={2}>
                {questions.map((question, index) => (
                  <Tooltip
                    key={uuidv4()}
                    label={`Navigate to Question No. ${index + 1}`}
                  >
                    <VStack>
                      <Button
                        size="xs"
                        onClick={() => navigateToQuestion(index)}
                        colorScheme={buttonColor}
                        variant={
                          isQuestionAnswered(question.id) ? 'solid' : 'outline'
                        }
                      >
                        {index + 1}
                      </Button>
                      {question.id === questions[position].id && (
                        <Divider
                          borderColor={buttonColor}
                          size={23}
                          variant="solid"
                        />
                      )}
                      {flagQuestions.find((q) => q === question.id) && (
                        <Icon
                          boxSize={3}
                          color={buttonColor}
                          size="xs"
                          as={MdFlag}
                        />
                      )}
                    </VStack>
                  </Tooltip>
                ))}
              </Flex>
            </Center>
          )}

          {isStarted && questions[position] && (
            <Box>
              <QuestionItem
                position={position + 1}
                question={questions[position]}
                selectedValue={findSelectedAnswer(questions[position].id)}
                handleSelectAnswer={handleSelectAnswer}
              />
            </Box>
          )}
        </Box>

        {isStarted && (
          <>
            <Divider />
            <Box px={4} py={2}>
              <Flex align="center">
                <Text fontSize="sm">
                  <Text as="span" fontWeight={500}>
                    {position + 1}
                  </Text>
                  &nbsp;of&nbsp;
                  <Text as="span" fontWeight={500}>
                    {questions.length}
                  </Text>
                  &nbsp;Question{questions.length > 1 && 's'}
                </Text>
                <Spacer />

                <Flex gap={4}>
                  <Tooltip label="Flag this question">
                    <IconButton
                      aria-label="previous"
                      icon={<MdFlag />}
                      onClick={() => {
                        handleFlagQuestion(questions[position].id);
                      }}
                      variant={
                        flagQuestions.find((q) => q === questions[position].id)
                          ? 'solid'
                          : 'outline'
                      }
                      colorScheme={buttonColor}
                      size="md"
                    />
                  </Tooltip>

                  <IconButton
                    aria-label="previous"
                    icon={<ArrowBackIcon />}
                    isDisabled={position === 0}
                    onClick={handlePrevQuestion}
                    variant="outline"
                    colorScheme={buttonColor}
                    size="md"
                  />

                  <IconButton
                    aria-label="next"
                    icon={<ArrowForwardIcon />}
                    isDisabled={position + 1 === questions.length}
                    onClick={handleNextQuestion}
                    variant="outline"
                    colorScheme={buttonColor}
                    size="md"
                  />
                  {questions.length ===
                    answers.filter((a) => a.values.length > 0).length && (
                    <Button
                      onClick={onOpenConfirmation}
                      colorScheme={buttonColor}
                    >
                      Submit
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}

QuizSubQuest.propTypes = {
  course: PropTypes.object.isRequired,
  quest: PropTypes.object.isRequired,
  subQuest: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
};

export default QuizSubQuest;
