import PropTypes from 'prop-types';
import { ArrowDownIcon, ArrowUpIcon, CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SubQuestItem from './SubQuestItem';

function QuestItem({
  courseId,
  quest,
  isMinimalMode = false,
  activeQuestId = null,
  activeSubQuestId = null,
}) {
  const [isToggled, setIsToggled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [subQuests, setSubQuests] = useState([]);

  const completedQuestSubQuest = useSelector(
    (state) =>
      state.progression.progressions.filter(
        (p) => p.questId === quest.id && p.dateFinished
      ).length
  );

  const totalSubQuestCount = useSelector(
    (state) =>
      state.progression.courseOutlines.find((o) => o.questId === quest.id)
        ?.totalSubQuestCount || 0
  );

  const isCompleted =
    totalSubQuestCount !== 0 && completedQuestSubQuest === totalSubQuestCount;

  useEffect(() => {
    const fetchSubQuests = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `/courses/${courseId}/quests/${quest.id}/sub-quests`
        );
        setSubQuests(response.data);
      } catch {
        setSubQuests([]);
      }
      setIsLoading(false);
    };
    fetchSubQuests();
  }, [courseId, quest]);

  const onToggleHandler = () => {
    setIsToggled(!isToggled);
  };

  const checkIconColor = useColorModeValue('green', 'white');

  return (
    <Box
      mb={isMinimalMode ? 0 : 6}
      boxShadow={activeQuestId === quest.id ? 'md' : ''}
      borderWidth={isMinimalMode ? '' : '1px'}
      borderRadius={isMinimalMode ? '' : 'md'}
    >
      <Box
        w="100%"
        p={isMinimalMode ? 1 : 4}
        bg={useColorModeValue(
          activeQuestId === quest.id ? '#FFDFDF' : '#f7f7f7',
          activeQuestId === quest.id ? 'teal' : 'gray.600'
        )}
      >
        <Flex align="center">
          <IconButton
            onClick={() => onToggleHandler()}
            variant="plain"
            aria-label={isToggled ? 'Untoggle quest' : 'Toggle quest'}
            icon={isToggled ? <ArrowUpIcon /> : <ArrowDownIcon />}
          />

          <Text
            fontWeight={500}
            fontSize={isMinimalMode ? 'xs' : 'md'}
            flex={1}
          >
            {quest?.name}
          </Text>

          {isCompleted > 0 && (
            <CheckCircleIcon boxSize={4} color={checkIconColor} />
          )}
        </Flex>
      </Box>

      <Collapse in={isToggled}>
        <Box p={4}>
          {!isMinimalMode && (
            <Text mb={5} fontSize={isMinimalMode ? 'xs' : 'md'}>
              {quest?.description}
            </Text>
          )}

          {isLoading ? (
            <Stack>
              <Skeleton
                mb={isMinimalMode ? 2 : 6}
                height={isMinimalMode ? '42px' : '72px'}
                borderRadius="md"
              />
              <Skeleton
                mb={isMinimalMode ? 2 : 6}
                height={isMinimalMode ? '42px' : '72px'}
                borderRadius="md"
              />
            </Stack>
          ) : (
            <Box>
              {subQuests?.map((subQuest) => (
                <SubQuestItem
                  key={subQuest.id}
                  courseId={courseId}
                  questId={quest.id}
                  subQuest={subQuest}
                  isMinimalMode={isMinimalMode}
                  activeSubQuestId={activeSubQuestId}
                />
              ))}
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
}

QuestItem.defaultProps = {
  isMinimalMode: false,
  activeSubQuestId: 0,
  activeQuestId: 0,
};

QuestItem.propTypes = {
  courseId: PropTypes.number.isRequired,
  quest: PropTypes.object.isRequired,
  isMinimalMode: PropTypes.bool,
  activeQuestId: PropTypes.number,
  activeSubQuestId: PropTypes.number,
};

export default QuestItem;
