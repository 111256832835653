import { createSlice } from '@reduxjs/toolkit';
import { ReducerStatus } from 'utils/constants';

import {
  createVideoSubQuest,
  createInteractiveContentSubQuest,
  createQuizSubQuest,
  createQuizSubQuestQuestions,
  updateVideoSubQuest,
  updateInteractiveContentSubQuest,
  updateQuizSubQuest,
  updateQuizSubQuestQuestions,
  removeSubQuest,
  updateSubQuestOrderNo,
  getSubQuestById,
} from './subquest-action';

const subquestSlice = createSlice({
  name: 'subquest',
  initialState: {
    uploadProgress: 0,
    questSubQuests: [],
    subQuest: null,
    questId: null,
    getSubQuest: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    createVideo: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    createInteractiveContent: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    createQuiz: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    createQuizQuestions: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateVideo: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateInteractiveContent: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateQuiz: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateQuizQuestions: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    removeSubQuest: {
      subQuestId: null,
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateSubQuestOrderNo: {
      status: ReducerStatus.IDLE,
      error: null,
    },
  },
  reducers: {
    addSubQuest(state, action) {
      state.questSubQuests = [...state.questSubQuests, action.payload];
    },
    setUploadProgress(state, action) {
      state.uploadProgress = action.payload;
    },
    setQuestId(state, action) {
      state.questId = action.payload;
    },
    setQuest(state, action) {
      state.quest = action.payload;
    },
    setSubQuestIdForRemoval(state, action) {
      state.removeSubQuest.subQuestId = action.payload;
    },
    arrangeOrderNo(state, action) {
      const { questId, subQuests } = action.payload;

      state.questSubQuests = state.questSubQuests.map((questSubQuest) =>
        questSubQuest.id === questId
          ? { ...questSubQuest, subQuests }
          : questSubQuest
      );
    },
    toggleVideoUpdateModal(state, action) {
      state.updateVideo.modal = action.payload;
    },
    toggleInteractiveContentUpdateModal(state, action) {
      state.updateInteractiveContent.modal = action.payload;
    },
    toggleQuizCreateModal(state, action) {
      state.createQuiz.modal = action.payload;
    },
    toggleQuizUpdateModal(state, action) {
      state.updateQuiz.modal = action.payload;
    },
    toggleRemoveModal(state, action) {
      state.removeSubQuest.modal = action.payload;
    },
    resetVideoUpdateStatus(state) {
      state.updateVideo.status = ReducerStatus.IDLE;
      state.editVideoSubQuestMode = false;
    },
    resetInteractiveContentUpdateStatus(state) {
      state.updateInteractiveContent.status = ReducerStatus.IDLE;
      state.editVideoSubQuestMode = false;
    },
    resetQuizUpdateStatus(state) {
      state.updateQuiz.status = ReducerStatus.IDLE;
      state.editQuizSubQuestMode = false;
    },
    resetRemoveStatus(state) {
      state.removeSubQuest.status = ReducerStatus.IDLE;
    },
    resetCreateVideoStatus(state) {
      state.createVideo.status = ReducerStatus.IDLE;
    },
    resetCreateVideo(state) {
      state.createVideo = {
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetCreateInteractiveContent(state) {
      state.createInteractiveContent = {
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetCreateQuiz(state) {
      state.createQuiz = {
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetVideoUpdate(state) {
      state.updateVideo = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetInteractiveContentUpdate(state) {
      state.updateInteractiveContent = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetQuizUpdate(state) {
      state.updateQuiz = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createVideoSubQuest.pending, (state) => {
        state.createVideo.status = ReducerStatus.LOADING;
      })
      .addCase(createInteractiveContentSubQuest.pending, (state) => {
        state.createInteractiveContent.status = ReducerStatus.LOADING;
      })
      .addCase(createQuizSubQuest.pending, (state) => {
        state.createQuiz.status = ReducerStatus.LOADING;
      })
      .addCase(createQuizSubQuestQuestions.pending, (state) => {
        state.createQuizQuestions.status = ReducerStatus.LOADING;
      })
      .addCase(createVideoSubQuest.fulfilled, (state, action) => {
        state.createVideo.status = ReducerStatus.SUCCEEDED;

        state.questSubQuests.forEach((item, index) => {
          if (item.id === action.payload.id) {
            state.questSubQuests[index].subQuests = [
              ...state.questSubQuests[index].subQuests,
              action.payload.subQuest,
            ];
          }
        });
        state.subQuest = null;
      })
      .addCase(createInteractiveContentSubQuest.fulfilled, (state, action) => {
        state.createInteractiveContent.status = ReducerStatus.SUCCEEDED;

        state.questSubQuests.forEach((item, index) => {
          if (item.id === action.payload.id) {
            state.questSubQuests[index].subQuests = [
              ...state.questSubQuests[index].subQuests,
              action.payload.subQuest,
            ];
          }
        });

        state.subQuest = null;
      })
      .addCase(createQuizSubQuest.fulfilled, (state, action) => {
        state.createQuiz.status = ReducerStatus.SUCCEEDED;

        state.questSubQuests.forEach((item, index) => {
          if (item.id === action.payload.id) {
            state.questSubQuests[index].subQuests = [
              ...state.questSubQuests[index].subQuests,
              action.payload.subQuest,
            ];
          }
        });

        state.subQuest = null;
      })
      .addCase(createQuizSubQuestQuestions.fulfilled, (state) => {
        state.createQuizQuestions.status = ReducerStatus.SUCCEEDED;
      })
      .addCase(createVideoSubQuest.rejected, (state, action) => {
        state.createVideo.status = ReducerStatus.FAILED;
        state.createVideo.error = action.payload;
      })
      .addCase(createInteractiveContentSubQuest.rejected, (state, action) => {
        state.createInteractiveContent.status = ReducerStatus.FAILED;
        state.createInteractiveContent.error = action.payload;
      })
      .addCase(createQuizSubQuest.rejected, (state, action) => {
        state.createQuiz.status = ReducerStatus.FAILED;
        state.createQuiz.error = action.payload;
      })
      .addCase(createQuizSubQuestQuestions.rejected, (state, action) => {
        state.createQuizQuestions.status = ReducerStatus.FAILED;
        state.createQuizQuestions.error = action.payload;
      })
      // Fetch SubQuest By Id
      .addCase(getSubQuestById.pending, (state) => {
        state.getSubQuest.status = ReducerStatus.LOADING;
      })
      .addCase(getSubQuestById.fulfilled, (state, action) => {
        state.getSubQuest.status = ReducerStatus.SUCCEEDED;
        state.subQuest = action.payload;
      })
      .addCase(getSubQuestById.rejected, (state, action) => {
        state.getSubQuest.status = ReducerStatus.FAILED;
        state.getSubQuest.error = action.payload;
      })
      // Update Video
      .addCase(updateVideoSubQuest.pending, (state) => {
        state.updateVideo.status = ReducerStatus.LOADING;
      })
      .addCase(updateVideoSubQuest.fulfilled, (state, action) => {
        state.updateVideo.status = ReducerStatus.SUCCEEDED;

        state.questSubQuests.forEach((questSubQuest, index) => {
          questSubQuest.subQuests.forEach((subQuest, subQuestIndex) => {
            if (subQuest.subQuest.id === action.payload.id) {
              state.questSubQuests[index].subQuests[subQuestIndex].subQuest =
                action.payload;
            }
          });
        });
        state.subQuest = null;
      })
      .addCase(updateVideoSubQuest.rejected, (state, action) => {
        state.updateVideo.status = ReducerStatus.FAILED;
        state.updateVideo.error = action.payload;
      })
      // Update Interactive Content
      .addCase(updateInteractiveContentSubQuest.pending, (state) => {
        state.updateInteractiveContent.status = ReducerStatus.LOADING;
      })
      .addCase(updateInteractiveContentSubQuest.fulfilled, (state, action) => {
        state.updateInteractiveContent.status = ReducerStatus.SUCCEEDED;

        state.questSubQuests.forEach((questSubQuest, index) => {
          questSubQuest.subQuests.forEach((subQuest, subQuestIndex) => {
            if (subQuest.subQuest.id === action.payload.id) {
              state.questSubQuests[index].subQuests[subQuestIndex].subQuest =
                action.payload;
            }
          });
        });
        state.subQuest = null;
      })
      .addCase(updateInteractiveContentSubQuest.rejected, (state, action) => {
        state.updateInteractiveContent.status = ReducerStatus.FAILED;
        state.updateInteractiveContent.error = action.payload;
      })
      // Update Quiz
      .addCase(updateQuizSubQuest.pending, (state) => {
        state.updateQuiz.status = ReducerStatus.LOADING;
      })
      .addCase(updateQuizSubQuest.fulfilled, (state, action) => {
        state.updateQuiz.status = ReducerStatus.SUCCEEDED;

        state.questSubQuests.forEach((questSubQuest, index) => {
          questSubQuest.subQuests.forEach((subQuest, subQuestIndex) => {
            if (subQuest.subQuest.id === action.payload.id) {
              state.questSubQuests[index].subQuests[subQuestIndex].subQuest =
                action.payload;
            }
          });
        });
        state.subQuest = null;
      })
      .addCase(updateQuizSubQuest.rejected, (state, action) => {
        state.updateQuiz.status = ReducerStatus.FAILED;
        state.updateQuiz.error = action.payload;
      })
      // Update Quiz Question
      .addCase(updateQuizSubQuestQuestions.pending, (state) => {
        state.updateQuizQuestions.status = ReducerStatus.LOADING;
      })
      .addCase(updateQuizSubQuestQuestions.fulfilled, (state) => {
        state.updateQuizQuestions.status = ReducerStatus.SUCCEEDED;
      })
      .addCase(updateQuizSubQuestQuestions.rejected, (state, action) => {
        state.updateQuizQuestions.status = ReducerStatus.FAILED;
        state.updateQuizQuestions.error = action.payload;
      })
      // Remove SubQuest By ID
      .addCase(removeSubQuest.pending, (state) => {
        state.removeSubQuest.status = ReducerStatus.LOADING;
      })
      .addCase(removeSubQuest.fulfilled, (state, action) => {
        state.removeSubQuest.status = ReducerStatus.SUCCEEDED;

        state.questSubQuests.forEach((questSubQuest, index) => {
          state.questSubQuests[index].subQuests =
            questSubQuest.subQuests.filter(
              (subQuest) => subQuest.subQuest.id !== action.payload
            );
        });
      })
      .addCase(removeSubQuest.rejected, (state, action) => {
        state.removeSubQuest.status = ReducerStatus.FAILED;
        state.removeSubQuest.error = action.payload;
      })
      // Update SubQuest Order No;
      .addCase(updateSubQuestOrderNo.pending, (state) => {
        state.updateSubQuestOrderNo.status = ReducerStatus.LOADING;
      })
      .addCase(updateSubQuestOrderNo.fulfilled, (state) => {
        state.updateSubQuestOrderNo.status = ReducerStatus.SUCCEEDED;
      })
      .addCase(updateSubQuestOrderNo.rejected, (state, action) => {
        state.updateSubQuestOrderNo.status = ReducerStatus.FAILED;
        state.updateSubQuestOrderNo.error = action.payload;
      });
  },
});

export const subquestActions = subquestSlice.actions;

export default subquestSlice;
