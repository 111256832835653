import PropTypes from 'prop-types';
import { AspectRatio, Box } from '@chakra-ui/react';
import { useMsal } from '@azure/msal-react';
import {
  generateActor,
  generateAuth,
  getProgress,
  LRSQL_URL,
} from 'config/lrsqlConfig';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { postProgressionAction } from 'store/modules/progression/progression-actions';
import { companionActions } from 'companion/store/companion-slice';
import { CompanionMode } from 'utils/constants';

function InteractiveContentSubQuest({ course, quest, subQuest, code }) {
  const baseFileUrl = process.env.REACT_APP_FILE_URL;

  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const { localAccountId } = accounts[0];

  const [isCompleted, setIsCompleted] = useState(false);
  const { filePath, indexName, transcript } = subQuest.subQuestInteractiveVideo;

  useEffect(() => {
    if (isCompleted) {
      dispatch(
        postProgressionAction({
          courseId: course.id,
          questId: quest.id,
          subQuestId: subQuest.id,
          code,
        })
      );
    }
  }, [isCompleted, course.id, quest.id, subQuest.id, code, dispatch]);

  useEffect(() => {
    let intervalId = null;
    const fetchAuthProgress = async () => {
      const response = await getProgress(
        localAccountId,
        course.id,
        quest.id,
        subQuest.id
      );

      if (response.length > 0) {
        setIsCompleted(true);
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(() => {
      fetchAuthProgress();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [localAccountId, course.id, quest.id, subQuest.id, setIsCompleted]);

  useEffect(() => {
    if (course && quest && subQuest) {
      setIsCompleted(false);
      const { name, objective } = subQuest;
      let videoContext = transcript;
      if (transcript === null || transcript === '') {
        videoContext = `Course: ${course.name} - ${course.description} | `;
        videoContext += `Quest: ${quest.name} - ${quest.description} | `;
        videoContext += `Video: ${name} - ${objective}`;
      }

      dispatch(companionActions.setContext({ videoContext }));
      dispatch(companionActions.setMode(CompanionMode.VIDEO_COMPANION));
      dispatch(
        companionActions.setGreetings(
          `I'm your AI Companion Bot, ready to assist you. I noticed that you're watching a video titled '${name}'. As your virtual trainer, feel free to ask me any questions you may have. I'm here to provide helpful information and guidance. So go ahead and ask away!`
        )
      );
    }
  }, [subQuest, course, quest, transcript, dispatch]);

  const getPath = () => {
    const params = `endpoint=${LRSQL_URL}/xapi/&auth=Basic ${generateAuth()}&actor=${generateActor(localAccountId, course.id, quest.id, subQuest.id)}`;
    return `${baseFileUrl}${filePath}/${indexName}?${params}`;
  };

  return (
    <Box borderWidth="1px">
      <AspectRatio h="80vh" ratio={16 / 9}>
        <iframe title="Interactive Content" src={getPath()} />
      </AspectRatio>
    </Box>
  );
}

InteractiveContentSubQuest.propTypes = {
  course: PropTypes.object.isRequired,
  quest: PropTypes.object.isRequired,
  subQuest: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
};

export default InteractiveContentSubQuest;
