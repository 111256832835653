import {
  Box,
  Center,
  Flex,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import QuestItem from 'components/quest/QuestItem';
import VideoSubQuest from 'components/subQuest/VideoSubQuest';

import 'components/common/styles/Scrollbar.css';

import { useDispatch, useSelector } from 'react-redux';

import { fetchProgressionByCourseIdAction } from 'store/modules/progression/progression-actions';
import InteractiveContentSubQuest from 'components/subQuest/InteractiveContentSubQuest';
import { removeHtmlTags } from 'utils/textParser';
import QuizSubQuest from 'components/subQuest/QuizSubQuest';

function SubQuestPage() {
  const { id, questId, subQuestId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quests, setQuests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [subQuest, setSubQuest] = useState(null);
  const [code, setCode] = useState(null);

  const courseOutlines = useSelector(
    (state) => state.progression.courseOutlines
  );

  useEffect(() => {
    setIsLoading(true);
    const fetchVideoContent = async () => {
      try {
        const getCourse = await axios.get(`/courses/${id}`);
        setCourse(getCourse.data);

        const getQuests = await axios.get(`/courses/${id}/quests`);
        setQuests(getQuests.data);

        const getSubQuest = await axios.get(
          `/courses/${id}/quests/${questId}/sub-quests/${subQuestId}`
        );

        document.title = `Skill Quest - ${getCourse.data.name} - ${getSubQuest.data.name}`;

        const getCode = await axios.post(`/progressions/`, {
          courseId: id,
          questId,
          subQuestId,
        });

        setCode(getCode.data);

        setSubQuest(getSubQuest.data);

        if (courseOutlines.length === 0)
          dispatch(fetchProgressionByCourseIdAction(id));
      } catch {
        navigate('/404');
      }

      setIsLoading(false);
    };

    fetchVideoContent();
  }, [id, questId, subQuestId, courseOutlines, dispatch, navigate]);

  return (
    <Flex minH="100vh" ml={{ base: 0, md: 20 }}>
      <Box flex="1" ml={2} p="5">
        <SkeletonText mb={4} noOfLines={1} isLoaded={!isLoading}>
          <Breadcrumbs
            mb={4}
            links={[
              { name: 'Home', path: '/' },
              { name: course?.name, path: `/courses/${id}` },
              {
                name: subQuest?.name,
                path: `/courses/${id}/quests/${questId}/sub-quests/${subQuestId}`,
              },
            ]}
          />
        </SkeletonText>

        <Flex direction={{ base: 'column', lg: 'row' }} gap={3}>
          <Box flex="1" h="100%">
            <Skeleton w="100%" h="100%" isLoaded={!isLoading}>
              {subQuest && !isLoading && subQuest?.type?.id === 1 && (
                <VideoSubQuest
                  h="100%"
                  course={course}
                  quest={quests.find(
                    (quest) => quest.id === parseInt(questId, 10)
                  )}
                  subQuest={subQuest}
                  code={code}
                />
              )}

              {subQuest && !isLoading && subQuest?.type?.id === 10 && (
                <InteractiveContentSubQuest
                  h="100%"
                  course={course}
                  quest={quests.find(
                    (quest) => quest.id === parseInt(questId, 10)
                  )}
                  subQuest={subQuest}
                  code={code}
                />
              )}

              {subQuest && !isLoading && subQuest?.type?.id === 4 && (
                <QuizSubQuest
                  h="100%"
                  course={course}
                  quest={quests.find(
                    (quest) => quest.id === parseInt(questId, 10)
                  )}
                  subQuest={subQuest}
                  code={code}
                />
              )}
            </Skeleton>

            <Box display={{ base: 'block', lg: 'none' }}>
              <SkeletonText noOfLines={2} isLoaded={!isLoading}>
                {subQuest && subQuest?.type?.id !== 4 && (
                  <Text mt={4} fontSize="lg" fontWeight="bold">
                    {subQuest?.name}
                  </Text>
                )}

                <Text mt={4} fontSize="lg" fontWeight="bold">
                  {subQuest?.name}
                </Text>
                <Text mb={8}>{subQuest?.objective}</Text>
              </SkeletonText>
            </Box>
          </Box>

          <Box w={{ base: '100%', lg: '350px' }}>
            <Box
              id="subquest-directory-container"
              className={useColorModeValue('scrollbar-light', 'scrollbar-dark')}
              overflowY="scroll"
              h="100%"
              borderColor={useColorModeValue('grey.800', 'teal')}
              borderWidth="1px"
              borderRadius="md"
            >
              <Center>
                <Text my={4} fontSize="xs" fontWeight="extrabold">
                  COURSE DIRECTORY
                </Text>
              </Center>

              {isLoading ? (
                <Stack>
                  <Skeleton mb={2} height="72px" />
                  <Skeleton mb={2} height="72px" />
                  <Skeleton mb={2} height="72px" />
                  <Skeleton mb={2} height="72px" />
                </Stack>
              ) : (
                <Box h="250px">
                  {quests.map((quest) => (
                    <QuestItem
                      key={quest.id}
                      courseId={parseInt(id, 10)}
                      quest={quest}
                      isMinimalMode
                      activeQuestId={parseInt(questId, 10)}
                      activeSubQuestId={parseInt(subQuestId, 10)}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Flex>

        <Box hideBelow="md" mt={4}>
          <SkeletonText noOfLines={2} isLoaded={!isLoading}>
            {subQuest && subQuest?.type?.id !== 4 && (
              <Text fontSize="lg" fontWeight="bold">
                {subQuest?.name}
              </Text>
            )}

            <Text>{removeHtmlTags(subQuest?.objective)}</Text>
          </SkeletonText>
        </Box>
      </Box>
    </Flex>
  );
}

export default SubQuestPage;
